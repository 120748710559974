import { PureComponent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Posts from './Posts';

const PostsPage = () => {

    return (
        <>
        <section className="section posts-entry">
            <div className="container">
                <div className="row mb-4">
                    <div className="col-sm-6">
                        <h2 className="posts-entry-title">Sve Objave</h2>
                    </div>
                    {/* <div className="col-sm-6 text-sm-end"><a href="category.html" className="read-more">View All</a></div> */}
                </div>
                <Posts/>
            </div>
        </section>        
    </>
    );
}

export default PostsPage;