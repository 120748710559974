import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const PostPage = () => {

    const [post, setPost] = useState([]);
    let { id } = useParams();

    useEffect( () => {
        
        const headers = new Headers();
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: "GET",
            headers: headers,
        }

        fetch(`${process.env.REACT_APP_BACKEND}/api/posts/${id}`, requestOptions)
            .then((response) => {
                return response.json();
            })
            .then((data) =>{
                setPost(data.post);
            })
            .catch(err => {
                console.log(err);
            });

    }, [id]);

    if (post.statuses) {
        post.statuses = Object.values(post.statuses);
    } else {
        post.statuses = [];
    }

    function renderPost(html) {
        return {__html: `${html}`};
    }

    return (
        <>
            <div >
                <div className="container">
                <div className="row same-height justify-content-center">
                    <div className="col-md-12">
                        <div className="post-entry text-center">
                            <br/>
                            <br/>
                            <h1 className="mb-12 source-serif-4">{post.title}</h1>
                            <br/>
                            <br/>
                            {post.statuses.map((s) => {
                                <span key={s.status} className='badge bg-secondary me-2'>{s.statuses}</span>
                            })}
                            <div className="post-meta align-items-center text-center">
                            {/* <figure className="author-figure mb-0 me-3 d-inline-block"><img src="./images/person_1.jpg" alt="Person" className="img-fluid" /></figure> */}
                            {/* <span className="d-inline-block mt-1">{post.author}</span>
                            <span>&nbsp;-&nbsp; {post.created_date}</span> */}
                            </div>
                        </div>
                        <div id="post-content" className="col-md-12" >
                            <div className="col-md-12" dangerouslySetInnerHTML={renderPost(post.html)} />
                            </div>
                            <br/>
                            <br/>
                        </div>
                    </div>

                </div>

            </div>
            
        </>
    )
}

export default PostPage;