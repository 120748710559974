import { PureComponent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Posts = () => {
    const [posts, setPosts] = useState([]);

    useEffect( () => {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: "GET",
            headers: headers,
        }

        fetch(`${process.env.REACT_APP_BACKEND}/api/posts`, requestOptions)
            .then((response) => {
                return response.json();
            })
            .then((data) =>{
                setPosts(data);
            })
            .catch(err => {
                console.log(err);
            });

    }, []);

    function renderPost(post) {
        return {__html: post.html};
    }

    return (
        <>
            <div className="row g-3">
                <div className="col-md-12">
                    <div className="row g-3">
                    {posts !== null && posts.length > 0 ? (posts.map((p) => (
                        <div key={p.id} className="col-md-3">
                            <div className="blog-entry">
                                <a href={`/posts/${p.id}`} className="img-link">
                                    <img src="./images/typewritter.jpg" alt="Image" className="img-fluid"></img>
                                </a>
                                <span className="date">{p.created_date}</span>
                                <h2>{p.title}</h2>
                                {/* <div dangerouslySetInnerHTML={renderPost(p)} /> */}
                                <p><Link to={`/posts/${p.id}`}  className="btn btn-sm btn-outline-primary">otvori</Link></p>
                            </div>
                        </div>
                    )))
                        :
                        <span>No posts found</span>
                    }
                    </div>
                </div>
                {/* <div className="col-md-3">
                    <ul className="list-unstyled blog-entry-sm">
                        <li>
                            <span className="date">Apr. 14th, 2022</span>
                            <h3><a href="single.html">Pet tema Bhagavad Gite</a></h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde, nobis ea quis inventore vel voluptas.</p>
                            <p><a href="#" className="read-more">Saznaj više</a></p>
                        </li>

                        <li>
                            <span className="date">Apr. 14th, 2022</span>
                            <h3><a href="single.html">Što su slavne osobe rekle o Bhagavad Giti</a></h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde, nobis ea quis inventore vel voluptas.</p>
                            <p><a href="#" className="read-more">Saznaj više</a></p>
                        </li>

                        <li>
                            <span className="date">Apr. 14th, 2022</span>
                            <h3><a href="single.html">Bog je Vrhovna osoba</a></h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde, nobis ea quis inventore vel voluptas.</p>
                            <p><a href="#" className="read-more">Saznaj više</a></p>
                        </li>
                    </ul>
                </div> */}
            </div>
        </>
    );
}

export default Posts;

