import { useState } from "react";
import Input from './form/Input';
import { useNavigate, useOutletContext, Link } from "react-router-dom";

const PasswordResetPage = () => {

    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState(""); 
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirm_password, setConfirmPassword] = useState("");

    const { setJwtToken } = useOutletContext();
    const { setAlertClassName } = useOutletContext();
    const { setAlertMessage } = useOutletContext();
    const {toggleRefresh } = useOutletContext();

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();

        // build the request payload
        let payload = {
            email: email,
        }

        const requestOptions = {
            method: 'Post',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(payload),
        }

        console.log(`${process.env.REACT_APP_BACKEND}/api/forgot-password`);

        fetch(`${process.env.REACT_APP_BACKEND}/api/forgot-password`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if(data.error) {
                    setAlertClassName('alert-danger');
                    setAlertMessage(data.message);
                } else {
                    // setJwtToken(data.access_token);
                    // setAlertClassName('d-none');
                    // setAlertMessage('');
                    // toggleRefresh(true);
                    navigate('/');
                }
            })
            .catch(error => {
                setAlertClassName('alert-danger');
                setAlertMessage(error);
            });
    }

    return (
        <>
            <div className="col-md-6 offset-md-3">
                <br/>
                <h2>Zaboravili ste svoju lozinku?</h2>
                <hr/>

                <form onSubmit={handleSubmit}>
                   <Input 
                        title="Email adresa"
                        type="email"
                        className="form-control"
                        name="email"
                        autoComplete="email-new"
                        onChange={(event) => setEmail(event.target.value)}
                   />
                   <hr/>

                   <input 
                        type="submit"
                        className="btn btn-primary"
                        value="Pošalji link"
                    />
                </form>
                <br/>
                <br/>
            </div>
        </>
    );
}

export default PasswordResetPage;