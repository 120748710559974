import React, { ChangeEvent } from 'react';

interface TextAreaProps {
    name: string;
    title: string;
    rows: number;
    placeholder: string;
    value: string;
    errorDiv: string;
    errorMsg: string;
    onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextArea: React.FC<TextAreaProps> = (props:any) => {
    return (
        <div className="mb-3">
            <label htmlFor={props.name} className="form-label">
                {props.title}
            </label>
            <textarea
                className="form-control"
                id={props.name}
                rows={props.rows}
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
            />
            <div className={props.errorDiv}>
                {props.errorMsg}
            </div>
        </div>
    );
}

export default TextArea;
