import { useEffect, useState, useContext, useCallback } from "react";
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { FaTrash, FaEdit } from "react-icons/fa";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/hr';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import {appContext, alertTypes} from '../App.jsx';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('hr');
dayjs.extend(advancedFormat)
dayjs().tz('Europe/Zagreb');

const ManageEvents = () => {
    const [events, setEvents] = useState([]);
    const { jwtToken } = useOutletContext();
    const navigate = useNavigate();
    const { toggleAlert, changeAlertType, toggleModalAlert, modalAlertResponseValue, itemToDelete, itemToDeleteAfterDialog } = useContext(appContext);
    const { setAlertTitle } = useOutletContext();
    const { setAlertMessage } = useOutletContext();

    let eventCategories = [
        {
            id: 1,
            value: "Bhagavad Gita",
            img: './../images/events/bg.jpg'
        },
        {
            id: 2,
            value: "Srimad Bhagavatam",
            img: './../images/events/sb.jpg'
        },
        {
            id: 3,
            value: "Kirtan večer",
            img: './../images/events/kirtan.jpg'
        },
        {
            id: 4,
            value: "Caitanya Caritamrta",
            img: './../images/events/cc.jpg'
        },
        {
            id: 5,
            value: "Posebna prigoda",
            img: './../images/events/byc-logo.png'
        },
        {
            id: 6,
            value: "Festival",
            img: './../images/events/byc-logo.png'
        },
        {
            id: 7,
            value: "Ostalo",
            img: './../images/events/byc-logo.png'
        },
    ];

    function loadEvents(jwtToken, navigate) {
        if(jwtToken === "") {
            navigate("/login");
            return;
        }
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${jwtToken}`);

        const requestOptions = {
            method: "GET",
            headers: headers,
            credentials: 'include',
        }

        fetch(`${process.env.REACT_APP_BACKEND}/admin/events`, requestOptions)
            .then((response) => {
                if(response.status === 401) {
                    console.log('Unauthorized');
                    navigate("/login");
                }

                return response.json();
            })
            .then((data) =>{
                setEvents(data);
            })
            .catch(err => {
                console.log(err);
            });
    }

    useEffect( () => {
        loadEvents(jwtToken, navigate);

        if(modalAlertResponseValue) {
            console.log('starting to delete ', itemToDeleteAfterDialog);
            deleteEvent(itemToDeleteAfterDialog)
        }

    }, [jwtToken, navigate, modalAlertResponseValue, itemToDeleteAfterDialog]);

    function getEventImage(event) {
        const result = eventCategories.find(({ id }) => id === event.category);
        return result.img;
    }

    function renderEvent(event) {
        return {__html: event.html};
    }

    function getEventDates(event) {

        const startDate = dayjs(event.start_datetime).tz('Europe/Zagreb');
        const endDate = dayjs(event.end_datetime).tz('Europe/Zagreb');
        const formattedStartDate = startDate.format('dddd, D. MMM, YYYY HH:mm');
        const formattedEndDate = endDate.format('dddd, D. MMM, YYYY HH:mm');

        if (startDate.isSame(endDate, 'date')) {
            return `${startDate.format('dddd, D. MMM')} (${startDate.format('HH:mm')} - ${endDate.format('HH:mm')})`;
        }

        return `${formattedStartDate} - ${formattedEndDate}`;
    }

    const deleteConfirmation = (event, id) => {
        event.preventDefault();
        setAlertTitle("Warning");
        setAlertMessage("Are you sure you want to delete this event?");
        itemToDelete(id);
        toggleModalAlert(true);
    }

    const deleteEvent = useCallback( (eventID) => {



        //return async (element) => {
        //element.preventDefault();
        console.log('event delete...');
        console.log(eventID);

        if(jwtToken === "") {
            navigate("/login");
            return;
        }
        const headers = new Headers();
         headers.append("Content-Type", "application/json");
         headers.append("Authorization", `Bearer ${jwtToken}`);

         const requestOptions = {
             method: "Get",
             headers: headers,
             credentials: 'include',
         }

         fetch(`${process.env.REACT_APP_BACKEND}/admin/delete-event/${eventID}`, requestOptions)
             .then((response) => {
                 if(response.status === 401) {
                     console.log('Unauthorized');
                     navigate("/login");
                 }

                 return response.json();
             })
             .then((data) =>{
                if(data.error) {
                    changeAlertType(alertTypes.Danger);
                    setAlertTitle("Warning");
                    setAlertMessage(data.message);
                } else {
                    changeAlertType(alertTypes.Success);
                    setAlertTitle("Event was successfully deleted!");
                    setAlertMessage(data.message);
                }

                toggleAlert(true);
                setEvents("");
                loadEvents(jwtToken, navigate);
             })
             .catch(err => {
                changeAlertType(alertTypes.Danger);
                setAlertTitle("Greška");
                setAlertMessage(err);
                console.log(err);
             });
        //}
    });

    const navigateTo = (event, relativeUrl) => {
        event.preventDefault();
        navigate(relativeUrl);
    }

    return (
        <>
            <div className="col-md-10 offset-md-1">
                <br />
                <h2>Kreiraj novi događaj</h2>
                <br />
                <Link to={`/admin/create-event`}  className="btn btn-sm btn-outline-primary">Novi događaj</Link>
            </div>
            <br />
            <hr />
            <br />
            <div className="col-md-10 offset-md-1">
            <br />
            <h2>Uredi postojeće događaje</h2>
            <br />
            <br />
            <div className="row row-cols-2 row-cols-lg-5 g-2 g-lg-3">
                {events !== null && events.length > 0 ? (events.map((e) => (
                    <div key={e.id} className="col-md-3">

                        <div className="blog-entry">
                            <a className="img-link" onClick={(event) => navigateTo(event, `/admin/events/${e.id}`)}>
                                <img src={getEventImage(e)} alt="Image" className="img-fluid"></img>
                            </a>
                            <span className="date">{getEventDates(e)}</span>
                            <h4>{e.title}</h4>
                            <div className="row row-cols-2">
                                <div className="col-md-2 m-1">
                                    <Link to={`/admin/events/${e.id}`}  className="btn btn-sm btn-outline-primary"><FaEdit/></Link>
                                </div>
                                <div className="col-md-2 m-1">
                                    <Link to={`/admin/delete-event/${e.id}`} onClick={(event) => deleteConfirmation(event, e.id)}  className="btn btn-sm btn-outline-primary"><FaTrash /></Link>
                                </div>
                            </div>
                        </div>

                    </div>

                )))
                    :
                    <span>No events found</span>
                }

                    </div>
                <div/>
            </div>

            <br />
        </>
    );
}

export default ManageEvents;
