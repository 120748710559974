import Events from './Events';

const EventsPage = () => {

    
    return (
        <>
        <section className="section posts-entry">
            <div className="container">
                <div className="row mb-4">
                    <div className="col-sm-6">
                        <h2 className="posts-entry-title">Raspored svih događanja</h2>
                    </div>
                </div>
                <Events/>
            </div>
        </section>        
    </>
    );
}

export default EventsPage;