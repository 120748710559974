
import { Link } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import { useState } from "react";

const headerImage = {
    height: '40vh',
    backgroundImage: "url('./images/kirtan.jpg')",
};

const BhaktiPage = () => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    return (
        <>
            <div className="site-cover site-cover-sm same-height overlay single-page" style={headerImage}>
            {/* <div className="container">
            <div className="row same-height justify-content-center">
                <div className="col-md-6">
                <div className="post-entry text-center">
                    <h1 className="mb-4">Šrila Prabhupada</h1>
                    <div className="post-meta align-items-center text-center">
                    <figure className="author-figure mb-0 me-3 d-inline-block"><img src="./images/person_1.jpg" alt="Placeholder" className="img-fluid" /></figure>
                    <span className="d-inline-block mt-1">Vladimir Pavić (Vrsabha Das)</span>
                    <span>&nbsp;-&nbsp; February 10, 2019</span>
                    </div>
                </div>
                </div>
            </div>
            </div> */}
        </div>
            <section className="section">
                <div className="container">
                <div className="row blog-entries element-animate">
                    <div className="col-md-12 col-lg-8 main-content">
                    <div className="post-content-body">
                        <br/>
                        <h1>Bhakti yoga - yoga ljubavi</h1>
                        <h4>(povezivanje s Bogom kroz ljubavnu predanost)</h4>
                        <br/>
                        <p>Sanskritska riječ yoga znači povezivanje. Yoga je drevna metoda duhovne prakse i filozofije opisana u najstarijim knjigama duhovne filozofske misli poznatim kao staroindijske Vede. Ta drevna duhovna praksa ima za cilj pomoći nam u razumijevanju sebe, svijeta koji nas okružuje, svoje svrhe postojanja i povezivanja sa svojim vječnim božanskim izvorom.</p>
                        <p>Postoji mnogo različitih vrsta yoge, kao što su kriya-yoga, hatha-yoga, kundalini-yoga, aštanga-yoga, koje su sve grane duhovne prakse yoge odnosno povezivanja.</p>
                        <p>U današnjem vremenu najčešće imamo površnu informaciju da je yoga skup vježbi i disanja namijenjenih boljem fizičkom i mentalnom stanju čovjeka. Međutim, pravi smisao yoge uvelike nadilazi fizičke i mentalne povoljnosti, koje se definitivno ostvaruju primjenom spomenutih jogičkih sustava, ali prava svrha yoge jest spoznati svoju vječnu prirodu kao djelića i samim time i sluge Boga.</p>
                        <div className="row my-4">
                        <div className="col-md-12 mb-4">
                            <img src="./images/bhakti/1.jpg" alt="placeholder" className="img-fluid rounded" />
                        </div>
                        <div className="col-md-6 mb-4">
                            <img src="./images/bhakti/2.jpg" alt="placeholder" className="img-fluid rounded" />
                        </div>
                        <div className="col-md-6 mb-4">
                            <img src="./images/bhakti/3.jpg" alt="placeholder" className="img-fluid rounded" />
                        </div>
                        </div>
                        <p>U sustavu aštanga-yoge, koji je izvorna metoda yoge, savršenstvo duhovne prakse nazivamo samadhi – potpuna spoznaja svog duhovnog identiteta koji se razlikuje od prolaznog tijela koje je vozilo nas vječnog duhovnog bića na putu ka vječnosti te spoznaja Svevišnje Božanske Osobe s kojom imamo odnos služenja s Ljubavlju i predanošću. Sustav aštanga-yoge sastoji se od osam stadija, koji uključuju i hatha-yogu (vježbe položaja tijela i disanje).</p>
                        <p>Međutim, za prosječna čovjeka u sadašnjem dobu primjena čak i nekih aspekata aštanga-yoge postaje nemoguća misija. Razloge u nemogućnosti primjene nalazimo u činjenici da uspješnost zahtijeva dugi niz godina potpune, nepokolebljive i nepodijeljene koncentracije, slijeđenje strogih pravila i propisa, izričito izbjegavanje udovoljavanja osjetilima i slijeđenje mnogih rigoroznih zahtjeva. U našem modernom društvu, onečišćenom s toliko puno uznemirenja, praktično je za većinu ljudi nemoguće primjenom aštanga-yoge dostići krajnji cilj - duhovnu spoznaju. Ova činjenica nikako ne umanjuje značaj aštanga-yoge, već ukazuje na zahtjevnost ovog sustava duhovne prakse koji je za većinu ljudi modernog doba preveliki izazov i stoga često obeshrabruje i pokušati primjenjivati yogu.</p>
                        <p>Bhakti Yoga je s druge strane vrlo prirodan i jednostavan, lako primjenjiv i najdjelotvorniji put koji velikoj većini ljudi omogućuje iskustvo oživljavanja naše vječne duhovne prirode, dovodeći nas do samospoznaje i spoznaje našeg vječnog odnosa s Bogom, prirodom i svijetom koji nas okružuje te nam omogućuje istinski mir i harmoniju suživota sa svim živim bićima. Jer je temeljna značajka duhovne spoznaje razumjeti duhovni izvor svih živih bića. Svi smo djelići Boga, duhovna iskra koja pokreće tijelo čovjeka, životinje, insekta, vodozemca uvijek zadržava svoju individualnost, ali svaka ta duhovna iskra je jednake karakteristike. Duhovna iskra koja pokreće tijelo čovjeka, bijele ili tamne puti je iste kvalitete, kao i duhovna iskra koja pokreće tijela drugih životnih vrsta i tu duhovnu iskru ne može stvoriti niti jedan oblik ljudskog nastojanja.</p>
                        <p>Ova je spoznaja iskustvo narastajućeg zadovoljstva koje, za razliku od prolaznog zadovoljstva osjetilne i mentalne ugode, ostaje iskustvo neprolazne sreće u potpunosti i ključ je za rješenje svih izazova s kojima se susreće i čovjek kao individua i svijet kao zajednica ljudi.</p>
                        <p>Nerazumijevanje u odnosima, svi oblici nasilja, počevši s obiteljskim, kriminal, terorizam, netolerancija, mržnja, konzumerizam i eksploatacija svih vrsta, trgovina ženama i djecom radi seksualnog iskorištavanja, ovisnosti svih vrsta – svi ovi problemi nastaju kao neutažena želja za zadovoljstvom. Želja za zadovoljstvom prirodna je i legitimna, ali može biti zadovoljena jedino kad se povežemo s Izvorom sveg zadovoljstva i Ljubavi, a to je Bog kojeg Vedske knjige znanja nazivaju Krišna - onaj koji privlači sva živa bića.</p>
                        <p>Ta povezanost s Bogom preduvjet je za stvaranje kulture i civilizacije nesebičnosti i formula je Mira jer nas opunomoćuje sposobnošću da svako živo biće vidimo kao dragog djelića Boga s kojim možemo razmjenjivati božansku Ljubav kroz razumijevanje - empatija, solidarnost, suosjećanje, brižnost, pomaganje i potpuna predanost u povjerenju Ljubavi. Obilježje te Ljubavi nikako nije oholost, elitizam i razni oblici taštine, već poniznost i zahvalnost, jer ta je Ljubav neprocjenjivi dar – bezuzročna milost.</p>
                        <h4>Maha Mantra - Velika molitva</h4>
                        <p>Recitiranje ili pjevanje Božjih imena (u formi mantre - duhovne zvučne formule), Hare Krišna, Hare Krišna, Krišna Krišna, Hare Hare, Hare Rama, Hare Rama, Rama Rama, Hare Hare, "O, Gospodinova energijo, o, Gospodine, molim vas, prihvatite me i uključite me u svoju transcendentalnu (duhovnu) službu”!, i pažljivo slušanje tema o Bogu, najdjelotvornija je metoda primjene bhakti-yoge. Izgovarajući transcendentalna imena Boga zapravo se družimo s Bogom, na taj način u dodiru s Gospodinovom duhovnom prirodom, naša izvorna duhovna priroda kao božjeg sluge postaje oživljena.</p>
                        <p>Hare Krišna mantra je najuzvišenija molitva jer ne tražimo od Gospodina ništa osim prilike da Ga služimo s Ljubavlju i Zahvalnošću. Ta molitva ima najveću moć da produhovi našu svjesnost i transformira je u svjesnost nesebične ljubavi. Ovaj drevni sustav yoge vjerodostojan je duhovni proces koji ima vrlo praktičnu primjenu u svim sferama života bilo kojeg čovjeka i dio je duhovne znanosti opisane u izvornoj vedskoj literaturi.</p>
                        <p>Om tat sat</p>
                    </div>
                    {/* <div className="pt-5">
                        <p>Categories:  <a href="#!">Food</a>, <a href="#!">Travel</a>  Tags: <a href="#!">#manila</a>, <a href="#!">#asia</a></p>
                    </div> */}
                    {/* <div className="pt-5 comment-wrap">
                        <h3 className="mb-5 heading">6 Comments</h3>
                        <ul className="comment-list">
                        <li className="comment">
                            <div className="vcard">
                            <img src="./images/person_1.jpg" alt="Image placeholder" />
                            </div>
                            <div className="comment-body">
                            <h3>Jean Doe</h3>
                            <div className="meta">January 9, 2018 at 2:21pm</div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Pariatur quidem laborum necessitatibus, ipsam impedit vitae autem, eum officia, fugiat saepe enim sapiente iste iure! Quam voluptas earum impedit necessitatibus, nihil?</p>
                            <p><a href="#!" className="reply rounded">Reply</a></p>
                            </div>
                        </li>
                        <li className="comment">
                            <div className="vcard">
                            <img src="./images/person_2.jpg" alt="Image placeholder" />
                            </div>
                            <div className="comment-body">
                            <h3>Jean Doe</h3>
                            <div className="meta">January 9, 2018 at 2:21pm</div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Pariatur quidem laborum necessitatibus, ipsam impedit vitae autem, eum officia, fugiat saepe enim sapiente iste iure! Quam voluptas earum impedit necessitatibus, nihil?</p>
                            <p><a href="#!" className="reply rounded">Reply</a></p>
                            </div>

                            <ul className="children">
                            <li className="comment">
                                <div className="vcard">
                                <img src="./images/person_3.jpg" alt="Image placeholder" />
                                </div>
                                <div className="comment-body">
                                <h3>Jean Doe</h3>
                                <div className="meta">January 9, 2018 at 2:21pm</div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Pariatur quidem laborum necessitatibus, ipsam impedit vitae autem, eum officia, fugiat saepe enim sapiente iste iure! Quam voluptas earum impedit necessitatibus, nihil?</p>
                                <p><a href="#!" className="reply rounded">Reply</a></p>
                                </div>
                                <ul className="children">
                                <li className="comment">
                                    <div className="vcard">
                                    <img src="./images/person_4.jpg" alt="Image placeholder" />
                                    </div>
                                    <div className="comment-body">
                                    <h3>Jean Doe</h3>
                                    <div className="meta">January 9, 2018 at 2:21pm</div>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Pariatur quidem laborum necessitatibus, ipsam impedit vitae autem, eum officia, fugiat saepe enim sapiente iste iure! Quam voluptas earum impedit necessitatibus, nihil?</p>
                                    <p><a href="#!" className="reply rounded">Reply</a></p>
                                    </div>

                                    <ul className="children">
                                    <li className="comment">
                                        <div className="vcard">
                                        <img src="./images/person_5.jpg" alt="Image placeholder" />
                                        </div>
                                        <div className="comment-body">
                                        <h3>Jean Doe</h3>
                                        <div className="meta">January 9, 2018 at 2:21pm</div>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Pariatur quidem laborum necessitatibus, ipsam impedit vitae autem, eum officia, fugiat saepe enim sapiente iste iure! Quam voluptas earum impedit necessitatibus, nihil?</p>
                                        <p><a href="#!" className="reply rounded">Reply</a></p>
                                        </div>
                                    </li>
                                    </ul>
                                </li>
                                </ul>
                            </li>
                            </ul>
                        </li>
                        <li className="comment">
                            <div className="vcard">
                            <img src="./images/person_1.jpg" alt="Image placeholder" />
                            </div>
                            <div className="comment-body">
                            <h3>Jean Doe</h3>
                            <div className="meta">January 9, 2018 at 2:21pm</div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Pariatur quidem laborum necessitatibus, ipsam impedit vitae autem, eum officia, fugiat saepe enim sapiente iste iure! Quam voluptas earum impedit necessitatibus, nihil?</p>
                            <p><a href="#!" className="reply rounded">Reply</a></p>
                            </div>
                        </li>
                        </ul>

                        <div className="comment-form-wrap pt-5">
                        <h3 className="mb-5">Leave a comment</h3>
                        <form action="#!" className="p-5 bg-light">
                            <div className="form-group">
                            <label htmlFor="name">Name *</label>
                            <input type="text" className="form-control" id="name" />
                            </div>
                            <div className="form-group">
                            <label htmlFor="email">Email *</label>
                            <input type="email" className="form-control" id="email" />
                            </div>
                            <div className="form-group">
                            <label htmlFor="website">Website</label>
                            <input type="url" className="form-control" id="website" />
                            </div>

                            <div className="form-group">
                            <label htmlFor="message">Message</label>
                            <textarea name="" id="message" cols="30" rows="10" className="form-control"></textarea>
                            </div>
                            <div className="form-group">
                            <input type="submit" value="Post Comment" className="btn btn-primary" />
                            </div>

                        </form>
                        </div>
                    </div> */}
                    </div>
                    <div className="col-md-12 col-lg-4 sidebar">
                    {/* <div className="sidebar-box search-form-wrap">
                        <form action="#!" className="sidebar-search-form">
                        <span className="bi-search"></span>
                        <input type="text" className="form-control" id="s" placeholder="Type a keyword and hit enter" />
                        </form>
                    </div> */}
                    <div className="sidebar-box">
                        <div className="bio text-center ">
                        <img src="./images/authors/sundarananda.jpg" alt="Image Placeholder" className="img-fluid mb-3" />
                            {/* <Stack direction="row" spacing={1}>
                                <Avatar 
                                    alt="Tomislav Klasnić (Sundarananda das)"
                                    src="./images/authors/sundarananda.jpg"
                                    sx={{ width: 128, height: 128 }}
                                />
                            </Stack> */}
                            
                            <div className="bio-body">
                                <h6><i>autor članka:</i></h6>
                                <h6><b>Tomislav Klasnić (Sundarananda das)</b></h6>
                                <h6></h6>
                                <p>Rođen je u Lipiku 1970 godine. Odrasta uz Novi Val i krajem svojeg srednjoškolskog obrazovanja postavlja si pitanja o smislu postojanja. Kroz vegetarijanstvo i yogu započinje svoju potraga za istinom. <Link onClick={handleExpandClick}>više...</Link></p>
                                
                                <Collapse in={expanded} timeout="auto" unmountOnExit>
                                <p>Na studiju geodezije rasplamsava se njegova ljubav prema Vedskom znanju i prakticiranju Bhakti yoge, koju nastavlja prakticirati i danas. Odluka o posvećenom proučavanju duhovne poruke Bhakti yoge dovodi ga u ašram, školu cjeloživotnog duhovnog obrazovanja. 1990 godine susreće se sa svojim Guruom, te kasnije dobiva incijaciju u Bhakti tradiciji Vedske škole duhovnog znanja. U Indiju, koljevku zanja o yogi, otputovao je već više od 15-ak puta. Deset godina je služio kao misionar a 15 godina kao voditelj ašrama. Osnivač je i predsjednik udruge Centar za Vedske studije. Organizator je i suorganizator brojnih duhovnih retrita, mantra meditacija, Kirtan festivala (koncerti duhovne glazbe) u Lisinskom, HNK-u i Bogaloo-u. Osnivač je projekata Bhakti Yoga Centar na <Link to="https://www.facebook.com/BYCZagreb/">FaceBooku</Link> te na <Link to="https://www.youtube.com/channel/UCZwJF4Ip3nS7dn25G2WvI7A">YouTubeu</Link>. Osnivač je i voditelj projekta <Link to="https://www.facebook.com/SoulFoodHr">Soul food</Link> (hrana za dušu, klupski zdravljak). Proučavatelj je i predavač Vedske filozofije u Bhakti yoga tradiciji duhovnog znanja Veda. Vrtlar organskog povrća i voća. Doživotni učenik kulture nesebičnosti.</p>
                                </Collapse>
                                {/* <p className="mb-4">Rođen je u Lipiku 1970 godine. Odrasta uz Novi Val i krajem svojeg srednjoškolskog obrazovanja postavlja si pitanja o smislu postojanja. Kroz vegetarijanstvo i yogu započinje svoju potraga za istinom. Na studiju geodezije rasplamsava se njegova ljubav prema Vedskom znanju i prakticiranju Bhakti yoge, koju nastavlja prakticirati i danas. Odluka o posvećenom proučavanju duhovne poruke Bhakti yoge dovodi ga u ašram, školu cjeloživotnog duhovnog obrazovanja. 1990 godine susreće se sa svojim Guruom, te kasnije dobiva incijaciju u Bhakti tradiciji Vedske škole duhovnog znanja. U Indiju, koljevku zanja o yogi, otputovao je već više od 15-ak puta. Deset godina je služio kao misionar a 15 godina kao voditelj ašrama. Osnivač je i predsjednik udruge Centar za Vedske studije. Organizator je i suorganizator brojnih duhovnih retrita, mantra meditacija, Kirtan festivala (koncerti duhovne glazbe) u Lisinskom, HNK-u i Bogaloo-u. Osnivač je projekata Bhakti Yoga Centar na <Link to="https://www.facebook.com/BYCZagreb/">FaceBook-u</Link> te na <Link to="https://www.youtube.com/channel/UCZwJF4Ip3nS7dn25G2WvI7A">YouTube-u</Link>. Osnivač je i voditelj projekta <Link to="https://www.facebook.com/SoulFoodHr">Soul food</Link> (hrana za dušu, klupski zdravljak). Proučavatelj je i predavač Vedske filozofije u Bhakti yoga tradiciji duhovnog znanja Veda. Vrtlar organskog povrća i voća. Doživotni učenik kulture nesebičnosti.</p> */}
                                {/* <p className="mb-4">Rođen je u Lipiku 1970 godine. Odrasta uz Novi Val i krajem svojeg srednjoškolskog obrazovanja postavlja si pitanja o smislu postojanja.</p>
                                <button className="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#moreDetails" aria-expanded="true" aria-controls="moreDetails">
                                    više...
                                </button>
                                <div className="collapse" id="moreDetails">
                                    <div class="card card-body">
                                        <p>Rođen je u Lipiku 1970 godine. Odrasta uz Novi Val i krajem svojeg srednjoškolskog obrazovanja postavlja si pitanja o smislu postojanja. Kroz vegetarijanstvo i yogu započinje svoju potraga za istinom. Na studiju geodezije rasplamsava se njegova ljubav prema Vedskom znanju i prakticiranju Bhakti yoge, koju nastavlja prakticirati i danas. Odluka o posvećenom proučavanju duhovne poruke Bhakti yoge dovodi ga u ašram, školu cjeloživotnog duhovnog obrazovanja. 1990 godine susreće se sa svojim Guruom, te kasnije dobiva incijaciju u Bhakti tradiciji Vedske škole duhovnog znanja. U Indiju, koljevku zanja o yogi, otputovao je već više od 15-ak puta. Deset godina je služio kao misionar a 15 godina kao voditelj ašrama. Osnivač je i predsjednik udruge Centar za Vedske studije. Organizator je i suorganizator brojnih duhovnih retrita, mantra meditacija, Kirtan festivala (koncerti duhovne glazbe) u Lisinskom, HNK-u i Bogaloo-u. Osnivač je projekata Bhakti Yoga Centar na <Link to="https://www.facebook.com/BYCZagreb/">FaceBook-u</Link> te na <Link to="https://www.youtube.com/channel/UCZwJF4Ip3nS7dn25G2WvI7A">YouTube-u</Link>. Osnivač je i voditelj projekta <Link to="https://www.facebook.com/SoulFoodHr">Soul food</Link> (hrana za dušu, klupski zdravljak). Proučavatelj je i predavač Vedske filozofije u Bhakti yoga tradiciji duhovnog znanja Veda. Vrtlar organskog povrća i voća. Doživotni učenik kulture nesebičnosti.</p>
                                    </div>
                                </div> */}

                                {/* <p><a href="#!" className="btn btn-primary btn-sm rounded px-2 py-2">Read my bio</a></p> */}
                                {/* <p className="social">
                                <a href="#!" className="p-2"><span className="fa fa-facebook"></span></a>
                                <a href="#!" className="p-2"><span className="fa fa-twitter"></span></a>
                                <a href="#!" className="p-2"><span className="fa fa-instagram"></span></a>
                                <a href="#!" className="p-2"><span className="fa fa-youtube-play"></span></a>
                                </p> */}
                            </div>
                        </div>
                    </div>
                    {/* <div className="sidebar-box">
                        <h3 className="heading">Popular Posts</h3>
                        <div className="post-entry-sidebar">
                        <ul>
                            <li>
                            <a href="">
                                <img src="./images/img_1_sq.jpg" alt="Image placeholder" className="me-4 rounded" />
                                <div className="text">
                                <h4>There’s a Cool New Way for Men to Wear Socks and Sandals</h4>
                                <div className="post-meta">
                                    <span className="mr-2">March 15, 2018 </span>
                                </div>
                                </div>
                            </a>
                            </li>
                            <li>
                            <a href="">
                                <img src="./images/img_2_sq.jpg" alt="Image placeholder" className="me-4 rounded" />
                                <div className="text">
                                <h4>There’s a Cool New Way for Men to Wear Socks and Sandals</h4>
                                <div className="post-meta">
                                    <span className="mr-2">March 15, 2018 </span>
                                </div>
                                </div>
                            </a>
                            </li>
                            <li>
                            <a href="">
                                <img src="./images/img_3_sq.jpg" alt="Image placeholder" className="me-4 rounded" />
                                <div className="text">
                                <h4>There’s a Cool New Way for Men to Wear Socks and Sandals</h4>
                                <div className="post-meta">
                                    <span className="mr-2">March 15, 2018 </span>
                                </div>
                                </div>
                            </a>
                            </li>
                        </ul>
                        </div>
                    </div> */}
                    {/* <div className="sidebar-box">
                        <h3 className="heading">Categories</h3>
                        <ul className="categories">
                        <li><a href="#!">Food <span>(12)</span></a></li>
                        <li><a href="#!">Travel <span>(22)</span></a></li>
                        <li><a href="#!">Lifestyle <span>(37)</span></a></li>
                        <li><a href="#!">Business <span>(42)</span></a></li>
                        <li><a href="#!">Adventure <span>(14)</span></a></li>
                        </ul>
                    </div>
                    <div className="sidebar-box">
                        <h3 className="heading">Tags</h3>
                        <ul className="tags">
                        <li><a href="#!">Travel</a></li>
                        <li><a href="#!">Adventure</a></li>
                        <li><a href="#!">Food</a></li>
                        <li><a href="#!">Lifestyle</a></li>
                        <li><a href="#!">Business</a></li>
                        <li><a href="#!">Freelancing</a></li>
                        <li><a href="#!">Travel</a></li>
                        <li><a href="#!">Adventure</a></li>
                        <li><a href="#!">Food</a></li>
                        <li><a href="#!">Lifestyle</a></li>
                        <li><a href="#!">Business</a></li>
                        <li><a href="#!">Freelancing</a></li>
                        </ul>
                    </div> */}
                    </div>
                </div>
            </div>
        </section>
                {/* <div className="row mb-4">
                    <div className="col-12 text-uppercase text-black">More Blog Posts</div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-lg-3">
                    <div className="blog-entry">
                        <a href="single.html" className="img-link">
                        <img src="./images/img_1_horizontal.jpg" alt="Placeholder" className="img-fluid" />
                        </a>
                        <span className="date">Apr. 14th, 2022</span>
                        <h2><a href="single.html">Thought you loved Python? Wait until you meet Rust</a></h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                        <p><a href="#!" className="read-more">Continue Reading</a></p>
                    </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                    <div className="blog-entry">
                        <a href="single.html" className="img-link">
                        <img src="./images/img_2_horizontal.jpg" alt="Placeholder" className="img-fluid" />
                        </a>
                        <span className="date">Apr. 14th, 2022</span>
                        <h2><a href="single.html">Startup vs corporate: What job suits you best?</a></h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                        <p><a href="#!" className="read-more">Continue Reading</a></p>
                    </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                    <div className="blog-entry">
                        <a href="single.html" className="img-link">
                        <img src="./images/img_3_horizontal.jpg" alt="Placeholder" className="img-fluid" />
                        </a>
                        <span className="date">Apr. 14th, 2022</span>
                        <h2><a href="single.html">UK sees highest inflation in 30 years</a></h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                        <p><a href="#!" className="read-more">Continue Reading</a></p>
                    </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                    <div className="blog-entry">
                        <a href="single.html" className="img-link">
                        <img src="./images/img_4_horizontal.jpg" alt="Placeholder" className="img-fluid" />
                        </a>
                        <span className="date">Apr. 14th, 2022</span>
                        <h2><a href="single.html">Don’t assume your user data in the cloud is safe</a></h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                        <p><a href="#!" className="read-more">Continue Reading</a></p>
                    </div>
                    </div>
                </div> */}
        </>
    )
}

export default BhaktiPage;