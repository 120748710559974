import { useEffect, useState, useContext } from "react";
import Input from './form/Input';
import { useNavigate, useOutletContext, Link } from "react-router-dom";
import {appContext, alertTypes} from '../App.jsx';

const RegistrationPage = () => {

    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState(""); 
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirm_password, setConfirmPassword] = useState("");

    const { toggleAlert, changeAlertType } = useContext(appContext)
    const { changeUserID } = useContext(appContext)
    const { setJwtToken } = useOutletContext();
    const { setIsAdmin } = useOutletContext();
    const { setAlertTitle } = useOutletContext();
    const { setAlertMessage } = useOutletContext();

    const {toggleRefresh } = useOutletContext();

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();

        // build the request payload
        let payload = {
            first_name: first_name,
            last_name: last_name,
            email: email,
            password: password,
            confirm_password: confirm_password,
        }

        const requestOptions = {
            method: 'Post',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(payload),
        }

        console.log(`${process.env.REACT_APP_BACKEND}/api/register`);

        fetch(`${process.env.REACT_APP_BACKEND}/api/register`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if(data.error) {
                    changeAlertType(alertTypes.Warning);
                    setAlertTitle("Warning");
                    setAlertMessage(data.message);
                } else {
                    setJwtToken(data.access_token);
                    setIsAdmin(data.is_admin); 
                    changeUserID(data.user_id);
                    changeAlertType(alertTypes.Success);
                    setAlertTitle("Uspješno ste registrirali vaš novi korisnički račun.");
                    toggleRefresh(true);
                    navigate('/');
                }
                toggleAlert(true);
            })
            .catch(err => {
                changeAlertType(alertTypes.Danger);
                setAlertTitle("Greška");
                setAlertMessage(err);
                toggleAlert(true);
                console.log(err)
            });
    }

    // const setEmail = (event) => {

    // }


    return (
        <>
            <div className="col-md-6 offset-md-3">
                <br/>
                <h2>Registriraj se</h2>
                <hr/>

                <form onSubmit={handleSubmit}>
                    <Input 
                        title="Ime"
                        type="text"
                        className="form-control"
                        name="first-name"
                        autoComplete="first-name-new"
                        onChange={(event) => setFirstName(event.target.value)}
                   />
                   <Input 
                        title="Prezime"
                        type="text"
                        className="form-control"
                        name="last-name"
                        autoComplete="last-name-new"
                        onChange={(event) => setLastName(event.target.value)}
                   />
                   <Input 
                        title="Email adresa"
                        type="email"
                        className="form-control"
                        name="email"
                        autoComplete="email-new"
                        onChange={(event) => setEmail(event.target.value)}
                   />
                   <Input 
                        title="Lozinka"
                        type="password"
                        className="form-control"
                        name="password"
                        autoComplete="password-new"
                        onChange={(event) => setPassword(event.target.value)}
                   />
                   <Input 
                        title="Ponovi lozinku"
                        type="password"
                        className="form-control"
                        name="password-confirm"
                        autoComplete="password-confirm-new"
                        onChange={(event) => setConfirmPassword(event.target.value)}
                   />

                   <hr/>

                   <input 
                        type="submit"
                        className="btn btn-primary"
                        value="Registriraj se"
                    />
                </form>
                <br/>
                <br/>
            </div>
        </>
    );
}

export default RegistrationPage;
