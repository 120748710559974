import { useState, useContext } from "react";
import Input from './form/Input';
import { useNavigate, useOutletContext, Link } from "react-router-dom";
import { appContext, alertTypes } from '../App.jsx';

const Login = () => {
    const { toggleAlert, changeAlertType } = useContext(appContext)
    const { changeUserID } = useContext(appContext)

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const { setJwtToken } = useOutletContext();
    const { setIsAdmin } = useOutletContext();
    const { setAlertTitle } = useOutletContext();
    const { setAlertMessage } = useOutletContext();
    const { toggleRefresh } = useOutletContext();

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();

        // build the request payload
        let payload = {
            email: email,
            password: password,
        }

        const requestOptions = {
            method: 'Post',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(payload),
        }

        console.log(`${process.env.REACT_APP_BACKEND}/api/authenticate`);

        fetch(`${process.env.REACT_APP_BACKEND}/api/authenticate`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if(data.error) {
                    changeAlertType(alertTypes.Warning);
                    setAlertTitle("Warning");
                    setAlertMessage(data.message);
                } else {
                    setJwtToken(data.access_token);
                    setIsAdmin(data.is_admin); 
                    changeUserID(data.user_id);
                    changeAlertType(alertTypes.Success);
                    setAlertTitle("Dobrodošli u Centar za Vedske Studije Zagreb");
                    toggleRefresh(true);
                    navigate('/');
                }
                toggleAlert(true);
            })
            .catch(error => {
                changeAlertType(alertTypes.Danger);
                setAlertTitle("Greška");
                setAlertMessage(error);
            });
    }

    return (
        <>
            <div className="col-md-6 offset-md-3">
                <br/>
                <h2>Ulogiraj se</h2>
                <hr/>

                <form onSubmit={handleSubmit}>
                   <Input 
                        title="Email adresa"
                        type="email"
                        className="form-control"
                        name="email"
                        autoComplete="email-new"
                        onChange={(event) => setEmail(event.target.value)}
                   />
                   <Input 
                        title="Lozinka"
                        type="password"
                        className="form-control"
                        name="password"
                        autoComplete="password-new"
                        onChange={(event) => setPassword(event.target.value)}
                   />

                   <hr/>

                   <input
                        type="submit"
                        className="btn btn-primary"
                        value="Ulogiraj se"
                    />

                    <br/>
                    <Link to="/forgot-password">Zaboravili ste lozinku?</Link>
                    <br/>
                    <hr/>
                    <br/>
                    <h6>Još uvijek nemate otvoren račun?</h6>
                    <Link to="/registration" className="btn btn-primary">Registriraj novi račun</Link>
                </form>
                <br/>
                <br/>
            </div>
        </>
    );
}

export default Login;
