import { useContext } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {appContext} from '../App.jsx';

const ModalAlert = (props) => {

  const handleClose = () => {
    modalReturnValue(false);
  }
  const { showModalAlert, modalReturnValue } = useContext(appContext)

  const closeAlert = () => {
    modalReturnValue(true); 
  };

  return (
    <>
      <Modal
        show={showModalAlert}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p dangerouslySetInnerHTML={{__html: props.message}} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => closeAlert()}>Understood</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalAlert;
