import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/hr';
import advancedFormat from 'dayjs/plugin/advancedFormat';

const placeholder = {
    height: '20vh',
    backgroundImage: "url(./images/carousel/placeholder.png)",
    display: 'flex',
    alignItems: 'center', 
}

const circularProgress = {
    display: 'block',
    margin: '0px auto',
}

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('hr');
dayjs.extend(advancedFormat)
dayjs().tz('Europe/Zagreb');

const Events = () => {
    const [isLoading, setLoading] = useState(true);
    const [events, setEvents] = useState([]);
    const navigate = useNavigate();

    let eventCategories = [
        {
            id: 1,
            value: "Bhagavad Gita",
            img: './../images/events/bg.jpg'
        },
        {
            id: 2,
            value: "Srimad Bhagavatam",
            img: './../images/events/sb.jpg'
        },
        {
            id: 3,
            value: "Kirtan večer",
            img: './../images/events/kirtan.jpg'
        },
        {
            id: 4,
            value: "Caitanya Caritamrta",
            img: './../images/events/cc.jpg'
        },
        {
            id: 5,
            value: "Posebna prigoda",
            img: './../images/events/byc-logo.png'
        },
        {
            id: 6,
            value: "Festival",
            img: './../images/events/byc-logo.png'
        },
        {
            id: 7,
            value: "Ostalo",
            img: './../images/events/byc-logo.png'
        },
    ];

    function getEventImage(event) {
        const result = eventCategories.find(({ id }) => id === event.category);
        return result.img;
    }

    function loadEvents() {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: "GET",
            headers: headers,
        }

        fetch(`${process.env.REACT_APP_BACKEND}/api/events-from-today`, requestOptions)
            .then((response) => {
                return response.json();
            })
            .then((data) =>{
                setEvents(data);
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            });
    }

    useEffect( () => {
        loadEvents();
    }, []);

    function getEvents() {
        
        if (events?.length > 0 )
        {
            return events
        }
        return [];
    }

    function getEventDates(event) {

        const startDate = dayjs(event.start_datetime).tz('Europe/Zagreb');
        const endDate = dayjs(event.end_datetime).tz('Europe/Zagreb');
        const formattedStartDate = startDate.format('dddd, D. MMM, YYYY HH:mm');
        const formattedEndDate = endDate.format('dddd, D. MMM, YYYY HH:mm');

        if (startDate.isSame(endDate, 'date')) {
            return `${startDate.format('dddd, D. MMM')} (${startDate.format('HH:mm')} - ${endDate.format('HH:mm')})`;
        }

        return `${formattedStartDate} - ${formattedEndDate}`;
    }

    const navigateTo = (event, relativeUrl) => {
        event.preventDefault();
        navigate(relativeUrl);
    }

    return (
        <>
            <div className="row g-3">
                <div className="col-md-12">
                    <div className="row g-3 align-self-center">
                    {
                        (() => {

                            if(isLoading) {
                                return (
                                    <div style={placeholder}>
                                        <CircularProgress style={circularProgress} />
                                    </div>
                                )
                            } else {
                                return(
                                    getEvents().map((e) => (
                                        <div key={e.id} className="col-md-4">
                                            <div className="blog-entry">
                                                <a className="img-link" onClick={(event) => navigateTo(event, `/events/${e.id}`)}>
                                                    <img src={getEventImage(e)} className="img-fluid"></img>
                                                </a>
                                                <span className="date">{getEventDates(e)}</span>
                                                <h4>{e.title}</h4>
                                                {/* {e.html} */}
                                                <p><Link to={`/events/${e.id}`}  className="btn btn-sm btn-outline-primary">Otvori</Link></p>
                                            </div>
                                        </div>

                                    )) 
                                )
                            }
                        })() 
                    }
                    </div>
                </div>
                {/* <div className="col-md-3">
                    <ul className="list-unstyled blog-entry-sm">
                        <li>
                            <span className="date">Apr. 14th, 2022</span>
                            <h3><a href="single.html">Raspored događanja za Prosinac</a></h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde, nobis ea quis inventore vel voluptas.</p>
                            <p><a href="#" className="read-more">Saznaj više</a></p>
                        </li>

                        <li>
                            <span className="date">Apr. 14th, 2022</span>
                            <h3><a href="single.html">Kako dobiti informacije o novim događanjima na email?</a></h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde, nobis ea quis inventore vel voluptas.</p>
                            <p><a href="#" className="read-more">Saznaj više</a></p>
                        </li>

                        <li>
                            <span className="date">Apr. 14th, 2022</span>
                            <h3><a href="single.html">Posebne prigode i festivali</a></h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde, nobis ea quis inventore vel voluptas.</p>
                            <p><a href="#" className="read-more">Saznaj više</a></p>
                        </li>
                    </ul>
                </div> */}
            </div>
        </>
    );
}

export default Events;

