import { useEffect, useState, useContext, useCallback } from "react";
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { FaTrash, FaEdit } from "react-icons/fa";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/hr';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import {appContext, alertTypes} from '../../App.jsx';
import Input from "../form/Input";
import Checkbox from "../form/CheckBox";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('hr');
dayjs.extend(advancedFormat)
dayjs().tz('Europe/Zagreb');

const ProfilePage = () => {
    const [userProfile, setUserProfile] = useState([{
        id: 0,
        first_name: "",
        last_name: "",
        receive_newsletter: false,
    }]);
    const { jwtToken } = useOutletContext();
    const [ errors, setErrors ] = useState([]);
    const navigate = useNavigate();
    const { toggleAlert, changeAlertType } = useContext(appContext);
    const { setAlertTitle } = useOutletContext();
    const { setAlertMessage } = useOutletContext();

    // get id from the URL
    let {id} = useParams();

    function loadUserProfile(jwtToken, navigate) {

        if(jwtToken === "") {
            navigate("/login");
            return;
        }
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${jwtToken}`);

        const requestOptions = {
            method: "GET",
            headers: headers,
            credentials: 'include',
        }

        fetch(`${process.env.REACT_APP_BACKEND}/admin/user-profile/${id}`, requestOptions)
            .then((response) => {
                if(response.status === 401) {
                    console.log('Unauthorized');
                    navigate("/login");
                }

                return response.json();
            })
            .then((data) =>{
                setUserProfile(data.user_profile);
                console.log(data.user_profile);
            })
            .catch(err => {
                console.log(err);
            });
    }

    useEffect( () => {
        loadUserProfile(jwtToken, navigate);

    }, [jwtToken, navigate]);

    // function renderEvent(event) {
    //     return {__html: event.html};
    // }

    // function getEventDates(event) {

    //     const startDate = dayjs(event.start_datetime).tz('Europe/Zagreb');
    //     const endDate = dayjs(event.end_datetime).tz('Europe/Zagreb');
    //     const formattedStartDate = startDate.format('dddd, D. MMM, YYYY HH:mm');
    //     const formattedEndDate = endDate.format('dddd, D. MMM, YYYY HH:mm');

    //     if (startDate.isSame(endDate, 'date')) {
    //         return `${startDate.format('dddd, D. MMM')} (${startDate.format('HH:mm')} - ${endDate.format('HH:mm')})`;
    //     }

    //     return `${formattedStartDate} - ${formattedEndDate}`;
    // }

    const handleSubmit = (event) => {
        event.preventDefault();

        if(jwtToken === "") {
            navigate("/login");
            return;
        }
        const headers = new Headers();
         headers.append("Content-Type", "application/json");
         headers.append("Authorization", `Bearer ${jwtToken}`);

         const requestOptions = {
             method: "Post",
             headers: headers,
             credentials: 'include',
             body: JSON.stringify(userProfile),
         }

         fetch(`${process.env.REACT_APP_BACKEND}/admin/update-profile`, requestOptions)
             .then((response) => {
                 if(response.status === 401) {
                     console.log('Unauthorized');
                     navigate("/login");
                 }

                 return response.json();
             })
             .then((data) =>{
                if(data.error) {
                    changeAlertType(alertTypes.Danger);
                    setAlertTitle("Warning");
                    setAlertMessage(data.message);
                } else {
                    changeAlertType(alertTypes.Success);
                    setAlertTitle("Spremljeno!");
                    //setAlertMessage(data.message);
                }

                toggleAlert(true);
             })
             .catch(err => {
                changeAlertType(alertTypes.Danger);
                setAlertTitle("Greška");
                setAlertMessage(err);
                toggleAlert(true);
                console.log(err)
             });
    }

    const handleChange = () => (event) => {
        let value = undefined; 
        if (event.target.type === "checkbox") {
            value = event.target.checked;
        }
        else {
            value = event.target.value;
        }
        
        let name = event.target.name;

        setUserProfile({
            ...userProfile,
            [name]: value
        });
    }

    const hasError = (key) => {
        return errors.indexOf(key) !== -1;
    }
    
    const navigateTo = (event, relativeUrl) => {
        event.preventDefault();
        navigate(relativeUrl);
    }


    return (
        <>
            {/* <div className="col-md-10 offset-md-1">
                {userProfile !== null ? 
                    (
                        <>
                            <br />
                            <h2>{userProfile.first_name} {userProfile.last_name}</h2>
                            <br />                
                            <br />
                            <hr />
                            <br />
                            <div className="col-md-10 offset-md-1">
                                <br />
                                <h2>Vaše postavke</h2>
                                <br />
                                <br />
                            </div>
                            <div className="col-md-10 offset-md-1">
                                Profil
                            </div>
                        </>
                    )
                :
                    <span>No user profile found</span>
                }
            </div> */}
            <div className="col-md-4 offset-md-4">
                <br/>
                {userProfile !== null ? 
                (
                    <>
                        <h2>Korisnički profil: {userProfile.first_name} {userProfile.last_name}</h2>
                        <hr/>
                        <form onSubmit={handleSubmit}>
                            <input type="hidden" name="id" value={userProfile.id} id="id"></input>
                            <Input
                                title={"Ime"}
                                className={"form-control"}
                                type={"text"}
                                name={"first_name"}
                                value={userProfile.first_name}
                                onChange={handleChange("first_name")}
                                errorDiv={hasError("first_name") ? "text-danger" : "d-none" }
                                errorMsg={"Polje 'Ime' ne smije biti prazno."}
                            />
                            <Input
                                title={"Prezime"}
                                className={"form-control"}
                                type={"text"}
                                name={"last_name"}
                                value={userProfile.last_name}
                                onChange={handleChange("last_name")}
                                errorDiv={hasError("last_name") ? "text-danger" : "d-none" }
                                errorMsg={"Polje 'Prezime' ne smije biti prazno."}
                            />
                            <Checkbox
                                title={"Želim primati mjesečni bilten (newsletter)"}
                                className={"form-control"}
                                name={"receive_newsletter"}
                                checked={userProfile.receive_newsletter}
                                onChange={handleChange("receive_newsletter")}
                            />
                            <hr/>
                            <input 
                                    type="submit"
                                    className="btn btn-primary"
                                    value="Spremi promjene"
                                />
                        </form>
                    </>
                 )
                 :
                     <span>Profil nije pronađen...</span>
                 }
                <br/>
                <br/>
                </div>
                <br />
                
               
        </>
    );
}

export default ProfilePage;
