
import { Link } from 'react-router-dom';
import { useEffect, useState, createContext } from 'react';
import CustomAlert from './components/Alert';
import CustomModalAlert from './components/Modal';
import { Outlet, useNavigate } from 'react-router-dom';
import { useCallback } from "react";

const navbarBrandCvs = {
  color: 'white',
  cursor: 'pointer',
}

const navItem = {
  cursor: 'pointer',
}

export const appContext = createContext();

export const alertTypes = Object.freeze({
  Primary: 'primary',
  Secondary: 'secondary',
  Success: 'success',
  Danger: 'danger',
  Warning: 'warning',
  Info: 'info',
  Light: 'light',
  Dark: 'dark',
});

const defaultAlertType = alertTypes.Info

function App() {
  const [jwtToken, setJwtToken] = useState("");
  const [isAdmin, setIsAdmin] = useState("");
  const [userID, setUserID] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState(defaultAlertType);
  const [showAlert, setShowAlert] = useState(false);
  const [showModalAlert, setShowModalAlert] = useState(false);
  const [modalAlertResponseValue, setModalAlertResponseValue] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [itemToDeleteAfterDialog, setItemToDelete] = useState("");

  console.log(`REACT_APP_BACKEND: ${process.env.REACT_APP_BACKEND}`)

  const [tickInterval, setTickInterval] = useState();

  const navigate = useNavigate();

  const getCurrentYear = () => {
    let currentYear =  new Date().getFullYear();
    return currentYear;
  }

  const toggleAlert = (data) =>{
    setShowAlert(data);
  }

  const toggleModalAlert = (data) =>{
    setShowModalAlert(data);
  }

  const changeAlertType = (alertType) => {
    setAlertType(alertType);
  }

  const changeUserID = (id) => {
    setUserID(id);
  }

  const itemToDelete = (id) =>{
    setItemToDelete(id);
  }

  const logOut = () => {
    const requestOptions = {
      method: 'GET',
      credentials: 'include',
    }

    fetch(`${process.env.REACT_APP_BACKEND}/logout`, requestOptions)
      .catch(error => {
        console.log("error logging out", error);
      })
      .finally(() => {
        setJwtToken("");
        setIsAdmin(false);
        setUserID("");
        toggleRefresh(false);
      });
      
    navigate("/");
  }

  const modalReturnValue = useCallback((returnVal) => {
    setShowModalAlert(false);
    console.log('Modal returned: ', returnVal);
    setModalAlertResponseValue(returnVal);
  });

  const toggleRefresh = useCallback((status) => {
    if(status) {
      let i = setInterval(() => {
        const requestOptions = {
          method: 'GET',
          credentials: 'include',
        }

        fetch(`${process.env.REACT_APP_BACKEND}/api/refresh`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (typeof data !== 'undefined') {
            if(data.access_token) {
              setJwtToken(data.access_token);
            } else {
              console.log('access token undefined');
            }

            if(data.is_admin) {
              setIsAdmin(data.is_admin);
              setUserID(data.user_id);
            }
            else {
              console.log('is_admin is undefined');
            }
          } else {
            console.log('data undefined');
          }
        })
        .catch(error => {
          console.log("error logging out");
        });
      }, 600000); // refresh auth 'cvszg' token every 600000 miliseconds (10 minutes)
      setTickInterval(i);
    } else {
      setTickInterval(null);
      clearInterval(tickInterval);
    }
  }, [tickInterval])

  useEffect(() => {

    setLoading(false);

    if(jwtToken === '') {
      const requestOptions = {
        method: 'GET',
        credentials: 'include',
      }

      fetch(`${process.env.REACT_APP_BACKEND}/api/refresh`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (typeof data !== 'undefined') {
          if(data.access_token) {
            setJwtToken(data.access_token);
            toggleRefresh(true);
          } else {
            console.log('access token undefined');
          }

          if(data.is_admin) {
            console.log('isadmin: ', data.is_admin);
            setIsAdmin(data.is_admin);
            setUserID(data.user_id);
          }
          else {
            console.log('is_admin is undefined');
          }

        } else {
          console.log('data undefined');
        }
      })
      .catch(error => {
        console.log("error logging out", error);
      });
    }
  }, [jwtToken, toggleRefresh])

  const navigateTo = (event, relativeUrl) => {
    event.preventDefault();
    navigate(relativeUrl);
  }

  return (
    <>
     <appContext.Provider value={{ alertTypes, alertType, showAlert, toggleAlert, toggleModalAlert, modalReturnValue, changeAlertType, changeUserID, showModalAlert, modalAlertResponseValue, itemToDelete, itemToDeleteAfterDialog}}>
      <div id="root">
        <div className="site-mobile-menu site-navbar-target">
          <div className="site-mobile-menu-header">
            <div className="site-mobile-menu-close">
              <span className="icofont-close js-menu-toggle"></span>
            </div>
          </div>
          <div className="site-mobile-menu-body"></div>
      </div>

      <nav className="main-nav navbar navbar-expand-lg">
        <div className="container">
            
          <a className="navbar-brand" style={navbarBrandCvs} onClick={(event) => navigateTo(event, "/")}>
            <img className="logo" style={navbarBrandCvs} src="./../images/byc-logo-sm.png" />
            
          </a>   
          <a className="navbar-brand" style={navbarBrandCvs} onClick={(event) => navigateTo(event, "/")}>  
            <span>CVS</span>     
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item" style={navbarBrandCvs}>
                <a className="nav-link active" aria-current="page" onClick={(event) => navigateTo(event, "/")}>Naslovnica</a>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Stranice
                </a>
                <ul className="dropdown-menu">
                  <li style={navbarBrandCvs}>
                    <a className="dropdown-item" onClick={(event) => navigateTo(event, "/events")}>
                      Raspored događanja
                    </a>
                  </li>
                  <li style={navbarBrandCvs}>
                    <a className="dropdown-item"  onClick={(event) => navigateTo(event, "/posts")}>
                      Objave
                    </a>
                  </li>
                  <li style={navbarBrandCvs}>
                    <hr className="dropdown-divider"/>
                  </li>
                  <li style={navbarBrandCvs}>
                    <a className="dropdown-item" onClick={(event) => navigateTo(event, "/vegetarianism")}>Vegetarijanstvo
                    </a>
                  </li>
                  <li style={navbarBrandCvs}>
                    <a className="dropdown-item" onClick={(event) => navigateTo(event, "/ecology")}>
                      Ekologija
                    </a>
                  </li>
                  <li style={navbarBrandCvs}>
                    <a className="dropdown-item" onClick={(event) => navigateTo(event, "/vedic-knowledge")}>
                      Vedsko znanje
                    </a>
                  </li>
                  <li style={navbarBrandCvs}>
                    <a className="dropdown-item" onClick={(event) => navigateTo(event, "/bhakti")}>
                      Bhakti Yoga
                    </a>
                  </li>
                  <li style={navbarBrandCvs}>
                    <a className="dropdown-item" onClick={(event) => navigateTo(event, "/srila-prabhupada")}>
                      Šrila Prabhupada
                    </a>
                  </li>
                </ul>
              </li>
              {/* <li className="nav-item" style={navbarBrandCvs}>
                <a className="nav-link active" onClick={(event) => navigateTo(event, "/shop")}>
                  Shop
                </a>
              </li> */}
              {/* <li className="nav-item" style={navbarBrandCvs}>
                <a className="nav-link active" onClick={(event) => navigateTo(event, "/about")}>
                  O nama
                </a>
              </li> */}
              <li className="nav-item" style={navbarBrandCvs}>
                <a className="nav-link active" onClick={(event) => navigateTo(event, "/contact")}>
                  Kontakt
                </a>
              </li>
              {jwtToken !== "" && isAdmin && userID !== "" && 
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Administracija
                </a>
                <ul className="dropdown-menu">
                  <li style={navbarBrandCvs}>
                    <a className="dropdown-item" onClick={(event) => navigateTo(event, `/admin/user-profile/${userID}`)}>
                      Uredi svoj korisnički profil
                    </a>
                  </li>
                  <li style={navbarBrandCvs}>
                    <a className="dropdown-item" onClick={(event) => navigateTo(event, "/admin/manage-events")}>
                      Uredi događanja
                    </a>
                  </li>
                  <li style={navbarBrandCvs}>
                    <a className="dropdown-item" onClick={(event) => navigateTo(event, "/admin/manage-posts")}>
                      Uredi objave
                    </a>
                  </li>
                  {/* <li style={navbarBrandCvs}>
                    <a className="dropdown-item" onClick={(event) => navigateTo(event, "/admin/add-post")}>
                      Nova objava
                    </a>
                  </li>
                  <li style={navbarBrandCvs}>
                    <a className="dropdown-item" onClick={(event) => navigateTo(event, "/admin/create-event")}>
                      Novi događaj
                    </a>
                  </li>
                  <li style={navbarBrandCvs}>
                    <a className="dropdown-item" onClick={(event) => navigateTo(event, "/admin/manage-nav")}>
                      Uredi navigaciju
                    </a>
                  </li>
                  <li style={navbarBrandCvs}>
                    <a className="dropdown-item" onClick={(event) => navigateTo(event, "/admin/manage-pages")}>
                      Uredi stranice
                    </a>
                  </li>
                  <li style={navbarBrandCvs}>
                    <a className="dropdown-item" onClick={(event) => navigateTo(event, "/admin/users")}>
                      Korisnici
                    </a>
                  </li>
                  <li style={navbarBrandCvs}>
                    <a className="dropdown-item" onClick={(event) => navigateTo(event, "/admin/manage-users")}>
                      Uredi korisnike
                    </a>
                  </li>
                  <li style={navbarBrandCvs}>
                    <a className="dropdown-item" onClick={(event) => navigateTo(event, "/admin/manage-comments")}>
                      Uredi komentare
                    </a>
                  </li> */}
                </ul>
              </li>
              }
              {jwtToken !== "" && !isAdmin &&
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Administracija
                </a>
                <ul className="dropdown-menu">
                  <li style={navbarBrandCvs}>
                    <a className="dropdown-item" onClick={(event) => navigateTo(event, `/admin/user-profile/${userID}`)}>
                      Uredi svoj korisnički profil
                    </a>
                  </li>
                </ul>
              </li>
              }

              <li className="nav-item" style={navbarBrandCvs}>
                {jwtToken === ""
                ? <a id='login-button' className="nav-link active" onClick={(event) => navigateTo(event, "/login")}>
                    <span className='badge bg-success'>
                      Ulogiraj se
                    </span>
                  </a>
                : <a id='logout-button' className="nav-link active" onClick={logOut}>
                    <span className='badge bg-danger'>
                      Odlogiraj se
                    </span>
                  </a>
                }
              </li>
            </ul>
            {/* <form className="d-flex" role="search">
              <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
              <button className="btn btn-outline-success" type="submit">Search</button>
            </form> */}
          </div>
        </div>
      </nav>

      {/* <nav className="site-nav">
        <div className="container">
          <div className="menu-bg-wrap">
            <div className="site-navigation">
              <div className="row g-0 align-items-center">
                <div className="col-2">
               
                  <Link to="/" className='logo m-0 float-start'>
                  
                  <span className="text-primary">
                    <img className="logo" src="./../images/byc-logo-sm.png" />  
                    CVS
                  </span>
                  </Link>
                </div>
                <div className="col-8 text-center">
    
                  <ul className="js-clone-nav d-none d-lg-inline-block text-start site-menu mx-auto">
                    <li className="active"><Link to="/">Naslovnica</Link></li>
                    <li className="has-children">
                      <Link to="#">Stranice</Link>
                      <ul className="dropdown">
                        <li><Link to="/events">Raspored događanja</Link></li>
                        <li><Link to="/posts">Objave</Link></li>
                        <li><Link to="/vegetarianism">Vegetarijanstvo</Link></li>
                        <li><Link to="/ecology">Ekologija</Link></li>
                        <li className="has-children">
                          <Link to="/vedic-knowledge">Vedsko znanje</Link>
                          <ul className="dropdown">
                            <li><Link to="/bhakti">Bhakti Yoga</Link></li>
                            <li><Link to="/srila-prabhupada">Šrila Prabhupada</Link></li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li><Link to="/shop">Shop</Link></li>
                    <li><Link to="/about">O nama</Link></li>
                    <li><Link to="/contact">Kontakt</Link></li>
                    {jwtToken !== "" && isAdmin &&
                    <>
                      <li className="has-children">
                        <Link to="#">Administracija</Link>
                        <ul className="dropdown">
                          <li><Link to="/admin/manage-events">Uredi događanja</Link></li>
                          <li><Link to="/admin/manage-posts">Uredi objave</Link></li>
                          <li><Link to="/admin/add-post">Nova objava</Link></li>
                          <li><Link to="/admin/create-event">Novi događaj</Link></li>
                          <li><Link to="/admin/manage-nav">Uredi navigaciju</Link></li>
                          <li><Link to="/admin/manage-pages">Uredi stranice</Link></li>
                          <li className="has-children">
                            <Link to="/admin/users">Korisnici</Link>
                            <ul className="dropdown">
                              <li><Link to="/admin/manage-users">Uredi korisnike</Link></li>
                              <li><Link to="/admin/manage-comments">Uredi komentare</Link></li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </>
                    }
                    <li>
                      {jwtToken === ""
                      ? <Link id='login-button' to="/login"><span className='badge bg-success'>Ulogiraj se</span></Link>
                      : <a id='logout-button' href='#!' onClick={logOut}><span className='badge bg-danger'>Odlogiraj se</span></a>
                      }
                    </li>
                  </ul>
                </div>
                <div className="col-2 text-end">
                  <Link to="#" className="burger ms-auto float-end site-menu-toggle js-menu-toggle d-inline-block d-lg-none light">
                    <span></span>
                  </Link>
                  <form action="/search-result" method="get" className="search-form d-none d-lg-inline-block"> 
                    <input type="text" className="form-control" name="search_input" placeholder="Search..."></input>
                    <span className="bi-search"></span>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav> */}
     
    <CustomAlert message={alertMessage} title={alertTitle} alertType={alertType} showAlert={showAlert}/>
    <CustomModalAlert message={alertMessage} title={alertTitle} showModalAlert={showModalAlert} />
     <Outlet context={{
      jwtToken,
      setJwtToken,
      setIsAdmin,
      setUserID,
      setAlertTitle,
      setAlertMessage,
      setAlertType,
      setShowAlert,
      toggleRefresh,
      modalReturnValue,
      modalAlertResponseValue,
      itemToDelete,
      itemToDeleteAfterDialog,
     }} />


     <footer className="site-footer">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            {/* <div className="widget">
              <h3 className="mb-12">Centar za Vedske Studije Zagreb</h3>
              <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
            </div> */}
            <div className="widget">
              <h3>Pratite nas na</h3>
              <Link className='btn' to="https://www.youtube.com/channel/UCZwJF4Ip3nS7dn25G2WvI7A"><span className="icon-youtube"></span></Link>
              {/* <br/> */}
              <Link className='btn' to="https://www.facebook.com/BYCZagreb"><span className="icon-facebook"></span></Link>
              {/* <ul className="list-unstyled social">
                <li><Link to="https://www.youtube.com/channel/UCZwJF4Ip3nS7dn25G2WvI7A"><span className="icon-youtube"></span></Link></li>
                <li><Link to="#"><span className="icon-twitter"></span></Link></li>
                <li><Link to="https://www.facebook.com/BYCZagreb"><span className="icon-facebook"></span></Link></li>
                <li><Link to="#"><span className="icon-linkedin"></span></Link></li>
                <li><Link to="#"><span className="icon-pinterest"></span></Link></li>
                <li><Link to="#"><span className="icon-dribbble"></span></Link></li>
              </ul> */}
            </div> 
          </div>
          {/* <div className="col-lg-4 ps-lg-5">
            <div className="widget">
              <h3 className="mb-4">Company</h3>
              <ul className="list-unstyled float-start links">
                <li><Link to="#">About us</Link></li>
                <li><Link to="#">Services</Link></li>
                <li><Link to="#">Vision</Link></li>
                <li><Link to="#">Mission</Link></li>
                <li><Link to="#">Terms</Link></li>
                <li><Link to="#">Privacy</Link></li>
              </ul>
              <ul className="list-unstyled float-start links">
                <li><Link to="#">Partners</Link></li>
                <li><Link to="#">Business</Link></li>
                <li><Link to="#">Careers</Link></li>
                <li><Link to="#">Blog</Link></li>
                <li><Link to="#">FAQ</Link></li>
                <li><Link to="#">Creative</Link></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="widget">
              <h3 className="mb-4">Recent Post Entry</h3>
              <div className="post-entry-footer">
                <ul>
                  <li>
                    <Link to="">
                      <img src="./images/img_1_sq.jpg" alt="Image placeholder" className="me-4 rounded"></img>
                      <div className="text">
                        <h4>There’s a Cool New Way for Men to Wear Socks and Sandals</h4>
                        <div className="post-meta">
                          <span className="mr-2">March 15, 2018 </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <img src="./images/img_2_sq.jpg" alt="Image placeholder" className="me-4 rounded"></img>
                      <div className="text">
                        <h4>There’s a Cool New Way for Men to Wear Socks and Sandals</h4>
                        <div className="post-meta">
                          <span className="mr-2">March 15, 2018 </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <img src="./images/img_3_sq.jpg" alt="Image placeholder" className="me-4 rounded"></img>
                      <div className="text">
                        <h4>There’s a Cool New Way for Men to Wear Socks and Sandals</h4>
                        <div className="post-meta">
                          <span className="mr-2">March 15, 2018 </span>
                        </div>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
  
  
            </div> 
          </div> */}
        </div>
  
        <div className="row mt-5">
          <div className="col-12 text-center">

              <p>
                Centar za Vedske Studije Zagreb<br/>&copy; Copyright 2016-{getCurrentYear()}<br/>Sva prava pridržana
              </p>
            </div>
          </div>
        </div>
  
      
    


     {/* <div className="container">
      <div className="row">
        <div className="col">
          <h1 className="mt-3">Centar za Vedske Studije</h1>
        </div>
      </div>
      <div className="col text-end">
        <Link to="#!"><span className="badge bg-success">Login</span></Link>
      </div>
     </div> */}
    </footer>

    </div>
    </appContext.Provider>
    </>
  );
}

export default App;
