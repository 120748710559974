import { useEffect, useState, useContext } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import Input from "./form/Input";
import Select from "./form/Select";
import TextArea from "./form/TextArea";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/de';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useRef } from "react";
import StarterKit from "@tiptap/starter-kit";
import TextStyle from '@tiptap/extension-text-style'
import {appContext, alertTypes} from '../App.jsx';

import {
    MenuButtonBold,
    MenuButtonItalic,
    MenuControlsContainer,
    MenuDivider,
    MenuSelectHeading,
    RichTextEditor,
    MenuSelectFontSize,
  } from "mui-tiptap";

import Button from '@mui/material/Button';

dayjs.extend(utc);
dayjs.extend(timezone);

const EditPost = () => {
    const rteRef = useRef(null);
    const navigate = useNavigate();
    const { jwtToken } = useOutletContext();

    const { toggleAlert, changeAlertType } = useContext(appContext)
    const { setAlertTitle } = useOutletContext();
    const { setAlertMessage } = useOutletContext();

    const [ error, setError ] = useState(null);
    const [ errors, setErrors ] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedAuthor, setSelectedAuthor] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);

    let categoryOptions = [
        {
            id: 1,
            value: 1
        },
        {
            id: 2,
            value: 2
        },
        {
            id: 3,
            value: 3
        },
    ]

    let authorOptions = [
        {
            id: 1,
            value: "Tomislav Klasnić (Sundarananda das)"
        },
        {
            id: 2,
            value: "Author 2"
        },
        {
            id: 3,
            value: "Author 3"
        },
    ];

    const hasError = (key) => {
        return errors.indexOf(key) !== -1;
    }

    const [ postPayload, setPost ] = useState({
        id: 0,
        title: "",
        author: 0,
        category: 0,
        description: "",
        html: "",
        status: 0,
        publish_at: "",
        publish_until: "",
    });

    const [ statusOptions, setStatuses ] = useState([]);

    // get id from the URL
    let {id} = useParams();

    useEffect(() => {
        if(jwtToken === "") {
            navigate("/login");
            return;
        }
        const headers = new Headers();
         headers.append("Content-Type", "application/json");
         headers.append("Authorization", `Bearer ${jwtToken}`);
 
         const requestOptions = {
             method: "GET",
             headers: headers,
             credentials: 'include',
         }
 
         fetch(`${process.env.REACT_APP_BACKEND}/admin/posts/${id}`, requestOptions)
             .then((response) => {
                 if(response.status === 401) {
                     console.log('Unauthorized');
                     navigate("/login");
                 }
 
                 return response.json();
             })
             .then((data) =>{
                 setPost(data.post);
                 setStatusOptions(data.statuses);
                 setSelectedCategory(data.post.category);
                 setSelectedAuthor(data.post.author);
                 setSelectedStatus(data.post.status);
                 //setPostPublishUntilDateTime(data.post.publish_until);
                // setPostPublishAtDateTime(data.post.publish_at);
                 
                 rteRef.current?.editor?.commands.setContent(data.post.html);
 
             })
             .catch(err => {
                 console.log(err);
             });
     }, [jwtToken, navigate, id])

     const handleSubmit = (event) => {
        event.preventDefault();

        if(jwtToken === "") {
            navigate("/login");
            return;
        }
        const headers = new Headers();
         headers.append("Content-Type", "application/json");
         headers.append("Authorization", `Bearer ${jwtToken}`);

         const requestOptions = {
             method: "Post",
             headers: headers,
             credentials: 'include',
             body: JSON.stringify(postPayload),
         }

         fetch(`${process.env.REACT_APP_BACKEND}/admin/update-post`, requestOptions)
             .then((response) => {
                 if(response.status === 401) {
                     console.log('Unauthorized');
                     navigate("/login");
                 }

                 return response.json();
             })
             .then((data) =>{
                if(data.error) {
                    changeAlertType(alertTypes.Danger);
                    setAlertTitle("Upozorenje");
                    setAlertMessage(data.message);
                } else {
                    changeAlertType(alertTypes.Success);
                    setAlertTitle("Objava je uspješno spremljena!");
                    //setAlertMessage(data.message);
                }

                toggleAlert(true);
             })
             .catch(err => {
                changeAlertType(alertTypes.Danger);
                setAlertTitle("Greška");
                setAlertMessage(err);
                toggleAlert(true);
                console.log(err)
             });
    }

    const handleChange = () => (event) => {
        let value = event.target.value;
        let name = event.target.name;

        setPost({
            ...postPayload,
            [name]: value
        });
    }

    const handleHtmlChange = () => (event) => {

        console.log(event)

        setPost({
            ...postPayload,
            ['html']: rteRef.current?.editor?.getHTML()
        });
    }

    const handleCategoryChange = () => (event) => {
        let value = parseInt(event.target.value);
        if (isNaN( parseFloat(value))) {
            value = 0;
        }
        let name = event.target.name;

        setSelectedCategory(value);

        setPost({
            ...postPayload,
            [name]: value
        });
    }

    const handleAuthorChange = () => (event) => {
        let value = parseInt(event.target.value);
        if (isNaN( parseFloat(value))) {
            value = 0;
        }
        let name = event.target.name;

        setSelectedAuthor(value);

        setPost({
            ...postPayload,
            [name]: value
        });
    }

    const handleStatusChange = () => (event) => {
        let value = parseInt(event.target.value);
        if (isNaN( parseFloat(value))) {
            value = 0;
        }
        let name = event.target.name;

        setSelectedStatus(value);

        setPost({
            ...postPayload,
            [name]: value
        });
    }

    const getPostPublishAtDateTime = (postDateTime) => {
        if (postDateTime !== ""){
            return dayjs(postDateTime);
        }

        return dayjs(new Date());
    }

    const getPostPublishUntilDateTime = (postDateTime) => {
        if (postDateTime !== ""){
            return dayjs(postDateTime);
        }

        return dayjs(new Date());
    }

    const setPostPublishAtDateTime = (postDateTime) => {
        if (postDateTime !== ""){
            let dateAt = dayjs(postDateTime) //.locale('zh-cn');

            setPost({
                ...postPayload,
                ["publish_at"]: dateAt
            });
         }
    }

    const setPostPublishUntilDateTime = (postDateTime) => {
        if (postDateTime !== ""){
            let dateUntil = dayjs(postDateTime) //.locale('zh-cn');

            setPost({
                ...postPayload,
                ["publish_until"]: dateUntil
            });
         }
    }

    const setStatusOptions = (statuses) => {
        let updatedStatuses = [];
        statuses.map(status => {
            updatedStatuses.push({
                id: status.id,
                value: status.name,
            })
        });

        setStatuses(updatedStatuses.sort((a, b) => a.id - b.id));
    }

    return (
        <>
            <appContext.Provider>
            <br/>
            <div className="col-md-10 offset-md-1">
                <div>
                    <h1>Uredi objavu</h1>
                </div>
                <pre>{JSON.stringify(postPayload, null, 3)}</pre>
                <hr/>
                <form onSubmit={handleSubmit}>
                    <input type="hidden" name="id" value={postPayload.id} id="id"></input>
                    <Input
                        title={"Naslov objave"}
                        className={"form-control"}
                        type={"text"}
                        name={"title"}
                        value={postPayload.title}
                        onChange={handleChange("title")}
                        errorDiv={hasError("title") ? "text-danger" : "d-none" }
                        errorMsg={"Molim vas unesite naslov"}
                    />

                    <TextArea
                        title="Bilješke"
                        name={"description"}
                        rows={"3"}
                        onChange={handleChange("description")}
                        // errorDiv={hasError("description") ? "text-danger" : "d-none" }
                        // errorMsg={"Please enter description"}
                    />

                    <div className="mb-3">
                        <label htmlFor="html" className="form-label">
                            Objava
                        </label>
                        <RichTextEditor
                            ref={rteRef}
                            name="html"
                            extensions={[StarterKit, TextStyle]} // Or any Tiptap extensions you wish!
                            content={postPayload.html} // Initial content for the editor
                            // Optionally include `renderControls` for a menu-bar atop the editor:
                            onUpdate={handleHtmlChange()}
                            //onChange={handleHtmlChange()}
                            renderControls={() => (

                            <MenuControlsContainer>
                                <MenuSelectHeading />
                                <MenuDivider />
                                <MenuButtonBold />
                                <MenuButtonItalic />
                                {/* <MenuSelectFontSize /> */}
                                {/* Add more controls of your choosing here */}
                            </MenuControlsContainer>
                            )}
                        />
                    </div>
                    <br />

                    <Select
                        title={"Kategorija"}
                        name={"category"}
                        options={categoryOptions}
                        value={selectedCategory}
                        //onChange={setSelectedOption}
                        onChange={handleCategoryChange("category")}
                        placeHolder={"Odaberite kategoriju objave..."}
                        //defaultValue={eventPayload.category}
                        // errorDiv={hasError("category") ? "text-danger" : "d-none" }
                        // errorMsg={"Please choose"}
                    />

                    <br />
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                        <DateTimePicker
                            name="publish_at"
                            label="Objavi ovu objavu na:"
                            className={"form-control"}
                            value={getPostPublishAtDateTime(postPayload.publish_at)}
                            onChange={(newValue) => setPostPublishAtDateTime(newValue)}
                        />
                    </LocalizationProvider>
                    <br /><br />
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                        <DateTimePicker
                            name="publish_until"
                            label="Arhiviraj ovu objavu na:"
                            className={"form-control"}
                            value={getPostPublishUntilDateTime(postPayload.publish_until)}
                            onChange={(newValue) => setPostPublishUntilDateTime(newValue)}
                        />
                    </LocalizationProvider>                      
                    {/* <Input
                        title={"Datum kreiranja objave"}
                        className={"form-control"}
                        type={"date"}
                        name={"publish_date"}
                        value={postPayload.publish_date}
                        onChange={handleChange("publish_date")}
                        errorDiv={hasError("publish_date") ? "text-danger" : "d-none" }
                        errorMsg={"Please enter a publish date"}
                    /> */}

                    {/* <Select
                        title={"Author"}
                        name={"author"}
                        options={authorOptions}
                        onChange={handleChange("author")}
                        placeHolder={"Choose..."}
                        errorDiv={hasError("author") ? "text-danger" : "d-none" }
                        errorMsg={"Please choose"}
                    /> */}

                    <br /><br />

                    <Select
                        title={"Autor"}
                        name={"author"}
                        options={authorOptions}
                        value={selectedAuthor}
                        //onChange={setSelectedOption}
                        onChange={handleAuthorChange("author")}
                        placeHolder={"Odaberite autora objave..."}
                        //defaultValue={eventPayload.category}
                        // errorDiv={hasError("category") ? "text-danger" : "d-none" }
                        // errorMsg={"Please choose"}
                    />

                    <br /><br />

                    <Select
                        title={"Status"}
                        name={"status"}
                        options={statusOptions}
                        value={selectedStatus}
                        //onChange={setSelectedOption}
                        onChange={handleStatusChange("status")}
                        placeHolder={"Odaberite status objave..."}
                        //defaultValue={eventPayload.category}
                        // errorDiv={hasError("category") ? "text-danger" : "d-none" }
                        // errorMsg={"Please choose"}
                    />

                    <Button type="submit" variant="outlined">Spremi promjene</Button>

                </form>
            </div>
            <br/>
            </appContext.Provider>
        </>
    )
}

export default EditPost;
