import { useEffect, useState, useContext, useCallback } from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { FaTrash, FaEdit } from "react-icons/fa";
import {appContext, alertTypes} from '../App.jsx';

const ManagePosts = () => {

    const [posts, setPosts] = useState([]);
    const { jwtToken } = useOutletContext();
    const navigate = useNavigate();
    const { toggleAlert, changeAlertType } = useContext(appContext);
    const { setAlertTitle } = useOutletContext();
    const { setAlertMessage } = useOutletContext();

    useEffect( () => {
        if(jwtToken === "") {
            navigate("/login");
            return; 
        }
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${jwtToken}`);

        const requestOptions = {
            method: "GET",
            headers: headers,
            credentials: 'include',
        }

        fetch(`${process.env.REACT_APP_BACKEND}/admin/posts`, requestOptions)
            .then((response) => {
                if(response.status === 401) {
                    console.log('Unauthorized');
                    navigate("/login");
                }

                return response.json();
            })
            .then((data) =>{
                setPosts(data);
            })
            .catch(err => {
                console.log(err);
            });

    }, [jwtToken, navigate]);

    const deletePost = useCallback( (postID) => {
        return async (element) => {
        element.preventDefault();
        console.log('post delete...');
        console.log(postID);

        if(jwtToken === "") {
            navigate("/login");
            return;
        }
        const headers = new Headers();
         headers.append("Content-Type", "application/json");
         headers.append("Authorization", `Bearer ${jwtToken}`);

         const requestOptions = {
             method: "Get",
             headers: headers,
             credentials: 'include',
         }

         fetch(`${process.env.REACT_APP_BACKEND}/admin/delete-post/${postID}`, requestOptions)
             .then((response) => {
                 if(response.status === 401) {
                     console.log('Unauthorized');
                     navigate("/login");
                 }

                 return response.json();
             })
             .then((data) =>{
                if(data.error) {
                    changeAlertType(alertTypes.Danger);
                    setAlertTitle("Warning");
                    setAlertMessage(data.message);
                } else {
                    changeAlertType(alertTypes.Success);
                    setAlertTitle("Post was successfully deleted!");
                    setAlertMessage(data.message);
                }

                toggleAlert(true);
                setPosts("");
                //loadPosts(jwtToken, navigate);
             })
             .catch(err => {
                changeAlertType(alertTypes.Danger);
                setAlertTitle("Greška");
                setAlertMessage(err);
                console.log(err);
             });
        }
    });

    function renderPost(post) {
        return {__html: post.html};
    }

    const navigateTo = (event, relativeUrl) => {
        event.preventDefault();
        navigate(relativeUrl);
    }

    return (
        <>
            {/* <div className="col-md-10 offset-md-1">
                <br />
                <h2>Kreiraj novu objavu</h2>
                <br />
                <Link to={`/admin/create-post`}  className="btn btn-sm btn-outline-primary">Nova objava</Link>
            </div>
            <br />
            <hr />
            <br /> */}
            <div className="col-md-10 offset-md-1">
            <br />
            <h2>Uredi postojeće objave</h2>
            <br />
            <br />
            <div className="row row-cols-2 row-cols-lg-5 g-2 g-lg-3">
                {posts !== null && posts.length > 0 ? (posts.map((p) => (
                    <div key={p.id} className="col-md-3">

                        <div className="blog-entry">
                            <a className="img-link" onClick={(event) => navigateTo(event, `/admin/posts/${p.id}`)}>
                                <img src="./../images/typewritter.jpg" alt="Image" className="img-fluid"></img>
                            </a>
                            <h4>{p.title}</h4>
                            <div className="row row-cols-2">
                                <div className="col-md-6">
                                    <Link to={`/admin/posts/${p.id}`}  className="btn btn-sm btn-outline-primary"><FaEdit/></Link>
                                    <Link to={`/admin/delete-post/${p.id}`} onClick={deletePost(p.id)}  className="btn btn-sm btn-outline-primary"><FaTrash /></Link>
                                </div>
                            </div>
                        </div>

                    </div>

                )))
                    :
                    <span>No posts found</span>
                }

                    </div>
                <div/>
            </div>

            <br />
        </>
    );
}

export default ManagePosts;
