
import { Link } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import { useState } from "react";

const headerImage = {
    height: '40vh',
    backgroundImage: "url('./images/vedic_knowledge.jpg')",
};

const SrilaPrabhupadaPage = () => {

    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    return (
        <>
        <div className="site-cover site-cover-sm same-height overlay single-page" style={headerImage}>
        </div>
        <section className="section">
            <div className="container">

                <div className="row blog-entries element-animate">

                    <div className="col-md-12 col-lg-8 main-content">

                    <div className="post-content-body">
                        <br/>
                        <h1>Vedsko znanje</h1>
                        <br/>
                      
                        <p>Vede su izvorni sanskrtski tekstovi drevne indijske duhovne kulture. Vede uključuju znanje o svim aspektima ljudskih nastojanja, materijalnih i duhovnih, poput skupa referentnih priručnika za postizanje konačnog uspjeha u ovom i sljedećem životu. "Veda" doslovno znači "znanje", a "vedski", u svom proširenom značenju, odnosi se na djela koja proširuju ili slijede Vede, uključujući Purane, Vedanta-sutru i Upanišade. Purane, ili "drevne povijesti," imaju za cilj učiniti Vede dostupnijima ljudima u sadašnjem dobu, a Bhagavat Purana, ili Srimad-Bhagavatam, naziva se suštinom, ili "kremom" cijele vedske literature.</p>
                        <div className="row my-4">
                        <div className="col-md-12 mb-4">
                            <img src="./images/vedic/1.jpg" alt="Image placeholder" className="img-fluid rounded" />
                        </div>
                        <div className="col-md-6 mb-4">
                            <img src="./images/vedic/2.jpg" alt="Image placeholder" className="img-fluid rounded" />
                        </div>
                        <div className="col-md-6 mb-4">
                            <img src="./images/vedic/3.jpg" alt="Image placeholder" className="img-fluid rounded" />
                        </div>
                        </div>
                        <p>Vede sadrže pravila ponašanja za dobrobit svih socioekonomskih klasa, vodiče za pridržavanje rituala za uzdizanje vlastite svijesti i društvenog statusa, zakone za održavanje reda unutar države i, naposljetku, upute kako probuditi duhovnu svijest.</p>
                        <p>Prema predaji, Vede postoje otkad postoje svjesna bića koja su ih čula. Ali tek od tridesetog stoljeća prije nove ere su uređene i pretočene u pisani oblik. Izvorna Veda podijeljena je na četiri - Rig, Yajur, Sama i Atharva Veda - zajedno s Puranama (povijestima),osamnaest Upanišada i konačno Vedanta-sutrom, koja sadrži vrlo sažete kodove. Kodove koje sažimaju konačni zaključak cjelokupnog znanja. Neki znanstvenici prihvaćaju samo četiri Vede kao istinski "vedske", ali mi koristimo taj izraz za označavanje cijele literature koja proširuje ili slijedi Vedska učenja.</p>
                        <p>Urednik i sastavljač Veda, Vyasadeva, sastavio je i Mahabharatu, ili "veliku povijest Zemlje", koja sadrži Krišnine upute Arjuni u poznatoj Bhagavad-giti. Vyasadevin komentar na Vedanta-sutru – Srimad-Bhagavatam – smatra se kremom cjelokupne vedske literature. Srimad-Bhagavatam posjeduje detaljne informacije o tome kako razviti ljubav prema Bogu slušajući o Njemu, Njegovim brojnim avatarima i Njegovim opunomoćenim predstavnicima.</p>
                        <br/>
                    </div>
                    {/* <div className="pt-5">
                        <p>Categories:  <a href="#!">Food</a>, <a href="#!">Travel</a>  Tags: <a href="#!">#manila</a>, <a href="#!">#asia</a></p>
                    </div> */}
                    {/* <div className="pt-5 comment-wrap">
                        <h3 className="mb-5 heading">6 Comments</h3>
                        <ul className="comment-list">
                        <li className="comment">
                            <div className="vcard">
                            <img src="./images/person_1.jpg" alt="Image placeholder" />
                            </div>
                            <div className="comment-body">
                            <h3>Jean Doe</h3>
                            <div className="meta">January 9, 2018 at 2:21pm</div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Pariatur quidem laborum necessitatibus, ipsam impedit vitae autem, eum officia, fugiat saepe enim sapiente iste iure! Quam voluptas earum impedit necessitatibus, nihil?</p>
                            <p><a href="#!" className="reply rounded">Reply</a></p>
                            </div>
                        </li>
                        <li className="comment">
                            <div className="vcard">
                            <img src="./images/person_2.jpg" alt="Image placeholder" />
                            </div>
                            <div className="comment-body">
                            <h3>Jean Doe</h3>
                            <div className="meta">January 9, 2018 at 2:21pm</div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Pariatur quidem laborum necessitatibus, ipsam impedit vitae autem, eum officia, fugiat saepe enim sapiente iste iure! Quam voluptas earum impedit necessitatibus, nihil?</p>
                            <p><a href="#!" className="reply rounded">Reply</a></p>
                            </div>

                            <ul className="children">
                            <li className="comment">
                                <div className="vcard">
                                <img src="./images/person_3.jpg" alt="Image placeholder" />
                                </div>
                                <div className="comment-body">
                                <h3>Jean Doe</h3>
                                <div className="meta">January 9, 2018 at 2:21pm</div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Pariatur quidem laborum necessitatibus, ipsam impedit vitae autem, eum officia, fugiat saepe enim sapiente iste iure! Quam voluptas earum impedit necessitatibus, nihil?</p>
                                <p><a href="#!" className="reply rounded">Reply</a></p>
                                </div>
                                <ul className="children">
                                <li className="comment">
                                    <div className="vcard">
                                    <img src="./images/person_4.jpg" alt="Image placeholder" />
                                    </div>
                                    <div className="comment-body">
                                    <h3>Jean Doe</h3>
                                    <div className="meta">January 9, 2018 at 2:21pm</div>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Pariatur quidem laborum necessitatibus, ipsam impedit vitae autem, eum officia, fugiat saepe enim sapiente iste iure! Quam voluptas earum impedit necessitatibus, nihil?</p>
                                    <p><a href="#!" className="reply rounded">Reply</a></p>
                                    </div>

                                    <ul className="children">
                                    <li className="comment">
                                        <div className="vcard">
                                        <img src="./images/person_5.jpg" alt="Image placeholder" />
                                        </div>
                                        <div className="comment-body">
                                        <h3>Jean Doe</h3>
                                        <div className="meta">January 9, 2018 at 2:21pm</div>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Pariatur quidem laborum necessitatibus, ipsam impedit vitae autem, eum officia, fugiat saepe enim sapiente iste iure! Quam voluptas earum impedit necessitatibus, nihil?</p>
                                        <p><a href="#!" className="reply rounded">Reply</a></p>
                                        </div>
                                    </li>
                                    </ul>
                                </li>
                                </ul>
                            </li>
                            </ul>
                        </li>
                        <li className="comment">
                            <div className="vcard">
                            <img src="./images/person_1.jpg" alt="Image placeholder" />
                            </div>
                            <div className="comment-body">
                            <h3>Jean Doe</h3>
                            <div className="meta">January 9, 2018 at 2:21pm</div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Pariatur quidem laborum necessitatibus, ipsam impedit vitae autem, eum officia, fugiat saepe enim sapiente iste iure! Quam voluptas earum impedit necessitatibus, nihil?</p>
                            <p><a href="#!" className="reply rounded">Reply</a></p>
                            </div>
                        </li>
                        </ul>

                        <div className="comment-form-wrap pt-5">
                        <h3 className="mb-5">Leave a comment</h3>
                        <form action="#!" className="p-5 bg-light">
                            <div className="form-group">
                            <label htmlFor="name">Name *</label>
                            <input type="text" className="form-control" id="name" />
                            </div>
                            <div className="form-group">
                            <label htmlFor="email">Email *</label>
                            <input type="email" className="form-control" id="email" />
                            </div>
                            <div className="form-group">
                            <label htmlFor="website">Website</label>
                            <input type="url" className="form-control" id="website" />
                            </div>

                            <div className="form-group">
                            <label htmlFor="message">Message</label>
                            <textarea name="" id="message" cols="30" rows="10" className="form-control"></textarea>
                            </div>
                            <div className="form-group">
                            <input type="submit" value="Post Comment" className="btn btn-primary" />
                            </div>

                        </form>
                        </div>
                    </div> */}
                    </div>
                    <div className="col-md-12 col-lg-4 sidebar">
                    {/* <div className="sidebar-box search-form-wrap">
                        <form action="#!" className="sidebar-search-form">
                        <span className="bi-search"></span>
                        <input type="text" className="form-control" id="s" placeholder="Type a keyword and hit enter" />
                        </form>
                    </div> */}
                    <div className="sidebar-box">
                        <div className="bio text-center ">
                        <img src="./images/authors/sundarananda.jpg" alt="Image Placeholder" className="img-fluid mb-3" />
                            {/* <Stack direction="row" spacing={1}>
                                <Avatar 
                                    alt="Tomislav Klasnić (Sundarananda das)"
                                    src="./images/authors/sundarananda.jpg"
                                    sx={{ width: 128, height: 128 }}
                                />
                            </Stack> */}
                            
                            <div className="bio-body">
                                <h6><i>autor članka:</i></h6>
                                <h6><b>Tomislav Klasnić (Sundarananda das)</b></h6>
                                <h6></h6>
                                <p>Rođen je u Lipiku 1970 godine. Odrasta uz Novi Val i krajem svojeg srednjoškolskog obrazovanja postavlja si pitanja o smislu postojanja. Kroz vegetarijanstvo i yogu započinje svoju potraga za istinom. <Link onClick={handleExpandClick}>više...</Link></p>
                                
                                <Collapse in={expanded} timeout="auto" unmountOnExit>
                                <p>Na studiju geodezije rasplamsava se njegova ljubav prema Vedskom znanju i prakticiranju Bhakti yoge, koju nastavlja prakticirati i danas. Odluka o posvećenom proučavanju duhovne poruke Bhakti yoge dovodi ga u ašram, školu cjeloživotnog duhovnog obrazovanja. 1990 godine susreće se sa svojim Guruom, te kasnije dobiva incijaciju u Bhakti tradiciji Vedske škole duhovnog znanja. U Indiju, koljevku zanja o yogi, otputovao je već više od 15-ak puta. Deset godina je služio kao misionar a 15 godina kao voditelj ašrama. Osnivač je i predsjednik udruge Centar za Vedske studije. Organizator je i suorganizator brojnih duhovnih retrita, mantra meditacija, Kirtan festivala (koncerti duhovne glazbe) u Lisinskom, HNK-u i Bogaloo-u. Osnivač je projekata Bhakti Yoga Centar na <Link to="https://www.facebook.com/BYCZagreb/">FaceBooku</Link> te na <Link to="https://www.youtube.com/channel/UCZwJF4Ip3nS7dn25G2WvI7A">YouTubeu</Link>. Osnivač je i voditelj projekta <Link to="https://www.facebook.com/SoulFoodHr">Soul food</Link> (hrana za dušu, klupski zdravljak). Proučavatelj je i predavač Vedske filozofije u Bhakti yoga tradiciji duhovnog znanja Veda. Vrtlar organskog povrća i voća. Doživotni učenik kulture nesebičnosti.</p>
                                </Collapse>
                                {/* <p className="mb-4">Rođen je u Lipiku 1970 godine. Odrasta uz Novi Val i krajem svojeg srednjoškolskog obrazovanja postavlja si pitanja o smislu postojanja. Kroz vegetarijanstvo i yogu započinje svoju potraga za istinom. Na studiju geodezije rasplamsava se njegova ljubav prema Vedskom znanju i prakticiranju Bhakti yoge, koju nastavlja prakticirati i danas. Odluka o posvećenom proučavanju duhovne poruke Bhakti yoge dovodi ga u ašram, školu cjeloživotnog duhovnog obrazovanja. 1990 godine susreće se sa svojim Guruom, te kasnije dobiva incijaciju u Bhakti tradiciji Vedske škole duhovnog znanja. U Indiju, koljevku zanja o yogi, otputovao je već više od 15-ak puta. Deset godina je služio kao misionar a 15 godina kao voditelj ašrama. Osnivač je i predsjednik udruge Centar za Vedske studije. Organizator je i suorganizator brojnih duhovnih retrita, mantra meditacija, Kirtan festivala (koncerti duhovne glazbe) u Lisinskom, HNK-u i Bogaloo-u. Osnivač je projekata Bhakti Yoga Centar na <Link to="https://www.facebook.com/BYCZagreb/">FaceBook-u</Link> te na <Link to="https://www.youtube.com/channel/UCZwJF4Ip3nS7dn25G2WvI7A">YouTube-u</Link>. Osnivač je i voditelj projekta <Link to="https://www.facebook.com/SoulFoodHr">Soul food</Link> (hrana za dušu, klupski zdravljak). Proučavatelj je i predavač Vedske filozofije u Bhakti yoga tradiciji duhovnog znanja Veda. Vrtlar organskog povrća i voća. Doživotni učenik kulture nesebičnosti.</p> */}
                                {/* <p className="mb-4">Rođen je u Lipiku 1970 godine. Odrasta uz Novi Val i krajem svojeg srednjoškolskog obrazovanja postavlja si pitanja o smislu postojanja.</p>
                                <button className="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#moreDetails" aria-expanded="true" aria-controls="moreDetails">
                                    više...
                                </button>
                                <div className="collapse" id="moreDetails">
                                    <div class="card card-body">
                                        <p>Rođen je u Lipiku 1970 godine. Odrasta uz Novi Val i krajem svojeg srednjoškolskog obrazovanja postavlja si pitanja o smislu postojanja. Kroz vegetarijanstvo i yogu započinje svoju potraga za istinom. Na studiju geodezije rasplamsava se njegova ljubav prema Vedskom znanju i prakticiranju Bhakti yoge, koju nastavlja prakticirati i danas. Odluka o posvećenom proučavanju duhovne poruke Bhakti yoge dovodi ga u ašram, školu cjeloživotnog duhovnog obrazovanja. 1990 godine susreće se sa svojim Guruom, te kasnije dobiva incijaciju u Bhakti tradiciji Vedske škole duhovnog znanja. U Indiju, koljevku zanja o yogi, otputovao je već više od 15-ak puta. Deset godina je služio kao misionar a 15 godina kao voditelj ašrama. Osnivač je i predsjednik udruge Centar za Vedske studije. Organizator je i suorganizator brojnih duhovnih retrita, mantra meditacija, Kirtan festivala (koncerti duhovne glazbe) u Lisinskom, HNK-u i Bogaloo-u. Osnivač je projekata Bhakti Yoga Centar na <Link to="https://www.facebook.com/BYCZagreb/">FaceBook-u</Link> te na <Link to="https://www.youtube.com/channel/UCZwJF4Ip3nS7dn25G2WvI7A">YouTube-u</Link>. Osnivač je i voditelj projekta <Link to="https://www.facebook.com/SoulFoodHr">Soul food</Link> (hrana za dušu, klupski zdravljak). Proučavatelj je i predavač Vedske filozofije u Bhakti yoga tradiciji duhovnog znanja Veda. Vrtlar organskog povrća i voća. Doživotni učenik kulture nesebičnosti.</p>
                                    </div>
                                </div> */}

                                {/* <p><a href="#!" className="btn btn-primary btn-sm rounded px-2 py-2">Read my bio</a></p> */}
                                {/* <p className="social">
                                <a href="#!" className="p-2"><span className="fa fa-facebook"></span></a>
                                <a href="#!" className="p-2"><span className="fa fa-twitter"></span></a>
                                <a href="#!" className="p-2"><span className="fa fa-instagram"></span></a>
                                <a href="#!" className="p-2"><span className="fa fa-youtube-play"></span></a>
                                </p> */}
                            </div>
                        </div>
                    </div>
                    {/* <div className="sidebar-box">
                        <h3 className="heading">Popular Posts</h3>
                        <div className="post-entry-sidebar">
                        <ul>
                            <li>
                            <a href="">
                                <img src="./images/img_1_sq.jpg" alt="Image placeholder" className="me-4 rounded" />
                                <div className="text">
                                <h4>There’s a Cool New Way for Men to Wear Socks and Sandals</h4>
                                <div className="post-meta">
                                    <span className="mr-2">March 15, 2018 </span>
                                </div>
                                </div>
                            </a>
                            </li>
                            <li>
                            <a href="">
                                <img src="./images/img_2_sq.jpg" alt="Image placeholder" className="me-4 rounded" />
                                <div className="text">
                                <h4>There’s a Cool New Way for Men to Wear Socks and Sandals</h4>
                                <div className="post-meta">
                                    <span className="mr-2">March 15, 2018 </span>
                                </div>
                                </div>
                            </a>
                            </li>
                            <li>
                            <a href="">
                                <img src="./images/img_3_sq.jpg" alt="Image placeholder" className="me-4 rounded" />
                                <div className="text">
                                <h4>There’s a Cool New Way for Men to Wear Socks and Sandals</h4>
                                <div className="post-meta">
                                    <span className="mr-2">March 15, 2018 </span>
                                </div>
                                </div>
                            </a>
                            </li>
                        </ul>
                        </div>
                    </div> */}
                    {/* <div className="sidebar-box">
                        <h3 className="heading">Categories</h3>
                        <ul className="categories">
                        <li><a href="#!">Food <span>(12)</span></a></li>
                        <li><a href="#!">Travel <span>(22)</span></a></li>
                        <li><a href="#!">Lifestyle <span>(37)</span></a></li>
                        <li><a href="#!">Business <span>(42)</span></a></li>
                        <li><a href="#!">Adventure <span>(14)</span></a></li>
                        </ul>
                    </div>
                    <div className="sidebar-box">
                        <h3 className="heading">Tags</h3>
                        <ul className="tags">
                        <li><a href="#!">Travel</a></li>
                        <li><a href="#!">Adventure</a></li>
                        <li><a href="#!">Food</a></li>
                        <li><a href="#!">Lifestyle</a></li>
                        <li><a href="#!">Business</a></li>
                        <li><a href="#!">Freelancing</a></li>
                        <li><a href="#!">Travel</a></li>
                        <li><a href="#!">Adventure</a></li>
                        <li><a href="#!">Food</a></li>
                        <li><a href="#!">Lifestyle</a></li>
                        <li><a href="#!">Business</a></li>
                        <li><a href="#!">Freelancing</a></li>
                        </ul>
                    </div> */}
                    </div>
                </div>
            </div>
        </section>
                {/* <div className="row mb-4">
                    <div className="col-12 text-uppercase text-black">More Blog Posts</div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-lg-3">
                    <div className="blog-entry">
                        <a href="single.html" className="img-link">
                        <img src="./images/img_1_horizontal.jpg" alt="Placeholder" className="img-fluid" />
                        </a>
                        <span className="date">Apr. 14th, 2022</span>
                        <h2><a href="single.html">Thought you loved Python? Wait until you meet Rust</a></h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                        <p><a href="#!" className="read-more">Continue Reading</a></p>
                    </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                    <div className="blog-entry">
                        <a href="single.html" className="img-link">
                        <img src="./images/img_2_horizontal.jpg" alt="Placeholder" className="img-fluid" />
                        </a>
                        <span className="date">Apr. 14th, 2022</span>
                        <h2><a href="single.html">Startup vs corporate: What job suits you best?</a></h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                        <p><a href="#!" className="read-more">Continue Reading</a></p>
                    </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                    <div className="blog-entry">
                        <a href="single.html" className="img-link">
                        <img src="./images/img_3_horizontal.jpg" alt="Placeholder" className="img-fluid" />
                        </a>
                        <span className="date">Apr. 14th, 2022</span>
                        <h2><a href="single.html">UK sees highest inflation in 30 years</a></h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                        <p><a href="#!" className="read-more">Continue Reading</a></p>
                    </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                    <div className="blog-entry">
                        <a href="single.html" className="img-link">
                        <img src="./images/img_4_horizontal.jpg" alt="Placeholder" className="img-fluid" />
                        </a>
                        <span className="date">Apr. 14th, 2022</span>
                        <h2><a href="single.html">Don’t assume your user data in the cloud is safe</a></h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                        <p><a href="#!" className="read-more">Continue Reading</a></p>
                    </div>
                    </div>
                </div> */}
        </>
    );
}

export default SrilaPrabhupadaPage;