const Select = (props) => {

    return(
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                <label className="input-group-text" for="inputGroupSelect01">{props.title}</label>
            </div>
            <select
                className="form-select"
                id={props.name}
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                defaultValue={props.defaultValue}
                >
                <option selected>{props.placeHolder}</option>
                {props.options.map(option => {
                    return (
                        <option
                            key={option.id}
                            value={option.id}
                        >
                            {option.value}
                        </option>
                    )
                })}
            </select>
            <div className={props.errorDiv}>
                {props.errorMsg}
            </div>
        </div>
    )
}

export default Select;