import { useEffect, useState, useContext } from "react";
import { useRef } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import Input from "./form/Input";
import Select from "./form/Select";
import TextArea from "./form/TextArea";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/de';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import StarterKit from "@tiptap/starter-kit";
import TextStyle from '@tiptap/extension-text-style'
import {appContext, alertTypes} from '../App.jsx';

import {
  MenuButtonBold,
  MenuButtonItalic,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectHeading,
  RichTextEditor,
} from "mui-tiptap";

import Button from '@mui/material/Button';

dayjs.extend(utc);
dayjs.extend(timezone);

const CreateEvent = () => {
    const rteRef = useRef(null);
    const navigate = useNavigate();
    const { toggleAlert, changeAlertType } = useContext(appContext)
    const { jwtToken } = useOutletContext();
    const { setAlertTitle } = useOutletContext();
    const { setAlertMessage } = useOutletContext();
    const [ errors, setErrors ] = useState([]);

    let eventCategories = [
        {
            id: 1,
            value: "Bhagavad Gita"
        },
        {
            id: 2,
            value: "Srimad Bhagavatam"
        },
        {
            id: 3,
            value: "Kirtan večer"
        },
        {
            id: 4,
            value: "Posebna prigoda"
        },
        {
            id: 5,
            value: "Festival"
        },
        {
            id: 6,
            value: "Ostalo"
        },
    ];

    const hasError = (key) => {
        return errors.indexOf(key) !== -1;
    }

    const [ eventPayload, setEvent ] = useState({
        id: 0,
        title: "",
        author: 1,
        category: 0,
        description: "",
        // html: "",
        start_datetime: dayjs(new Date()),
        end_datetime: dayjs(new Date()),
        created_at: "",
        updated_at: "",
    });

    useEffect(() => {
       if(jwtToken === "") {
           navigate("/login");
           return;
       }
    }, [jwtToken, navigate])

    const handleSubmit = (event) => {
        event.preventDefault();

        if(jwtToken === "") {
            navigate("/login");
            return;
        }
        const headers = new Headers();
         headers.append("Content-Type", "application/json");
         headers.append("Authorization", `Bearer ${jwtToken}`);

         eventPayload.created_at = dayjs(new Date());
         eventPayload.updated_at = dayjs(new Date());

         const requestOptions = {
             method: "Post",
             headers: headers,
             credentials: 'include',
             body: JSON.stringify(eventPayload),
         }

         fetch(`${process.env.REACT_APP_BACKEND}/admin/create-event`, requestOptions)
             .then((response) => {
                 if(response.status === 401) {
                     console.log('Unauthorized');
                     navigate("/login");
                 }
 
                 return response.json();
             })
             .then((data) =>{
                if(data.error) {
                    changeAlertType(alertTypes.Danger);
                    setAlertTitle("Warning");
                    setAlertMessage(data.message);
                } else {
                    changeAlertType(alertTypes.Success);
                    setAlertTitle("Event was successfully created!");
                    setAlertMessage(data.message);
                    navigate(`/admin/events/${data.data}`)
                }

                toggleAlert(true);
             })
             .catch(err => {
                changeAlertType(alertTypes.Danger);
                setAlertTitle("Greška");
                setAlertMessage(err);
                console.log(err);
             });
    }

    const handleChange = () => (event) => {
        let value = event.target.value;
        let name = event.target.name;

        setEvent({
            ...eventPayload,
            [name]: value
        });
    }

    const handleCategoryChange = () => (event) => {
        let value = parseInt(event.target.value);
        if (isNaN( parseFloat(value))) {
            value = 0;
        }
        let name = event.target.name;

        setEvent({
            ...eventPayload,
            [name]: value
        });
    }

    const getEventStartDateTime = (eventDateTime) => {
        if (eventDateTime !== ""){
            return dayjs(eventDateTime);
        }

        return dayjs(new Date());
    }

    const getEventEndDateTime = (eventDateTime) => {
        if (eventDateTime !== ""){
           return dayjs(eventDateTime);
        }
    }

    const setEventStartDateTime = (eventDateTime) => {
        if (eventDateTime !== ""){
            let date = dayjs(eventDateTime).locale('zh-cn');

            setEvent({
                ...eventPayload,
                ["start_datetime"]: date
            });
         }
    }

    const setEventEndDateTime = (eventDateTime) => {
        if (eventDateTime !== ""){
            let date = dayjs(eventDateTime).locale('zh-cn');

            setEvent({
                ...eventPayload,
                ["end_datetime"]: date
            });
         }
    }

    const handleDescriptionChange = () => (event) => {

        console.log(event)

        setEvent({
            ...eventPayload,
            ['description']: rteRef.current?.editor?.getHTML()
        });
    }

    return (
        <>
            <br/>
            <div className="col-md-10 offset-md-1">
            <div><h1>Create new event</h1></div>
            {/* <pre>{JSON.stringify(eventPayload, null, 3)}</pre> */}
            <hr/>
            <form onSubmit={handleSubmit}>
                <input type="hidden" name="id" value={eventPayload.id} id="id"></input>
                <Input
                    title={"Title"}
                    className={"form-control"}
                    type={"text"}
                    name={"title"}
                    value={eventPayload.title}
                    onChange={handleChange("title")}
                    errorDiv={hasError("title") ? "text-danger" : "d-none" }
                    errorMsg={"Please enter a title"}
                />

                {/* <TextArea
                    title="Description"
                    name={"description"}
                    rows={"3"}
                    value={eventPayload.description}
                    onChange={handleChange("description")}
                    errorDiv={hasError("description") ? "text-danger" : "d-none" }
                    errorMsg={"Please enter description"}
                /> */}


                
                <Button type="submit" variant="outlined">Next</Button>

                {/* <Button onClick={() => console.log(rteRef.current?.editor?.getHTML())}>
                    Log HTML
                </Button> */}


            </form>
            </div>
            <br/>
        </>
    )
}

export default CreateEvent;
