import { useEffect, useState, useContext, useCallback } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import Input from "./form/Input";
import Select from "./form/Select";
import TextArea from "./form/TextArea";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/de';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useRef } from "react";
import StarterKit from "@tiptap/starter-kit";
import TextStyle from '@tiptap/extension-text-style'
import { appContext, alertTypes } from '../App';
import { DriveFileRenameOutlineSharp, Lock, LockOpen, TextFields } from "@mui/icons-material";
import { Box, Button, Stack } from "@mui/material";
import type { EditorOptions } from "@tiptap/core";

import {
    LinkBubbleMenu,
    MenuButton,
    RichTextEditor,
    TableBubbleMenu,
    insertImages,
    type RichTextEditorRef,
} from "mui-tiptap";
import EditorMenuControls from "./editor/EditorMenuControls";
import useExtensions from "./editor/useExtensions";
import { EditorView } from "@tiptap/pm/view";

dayjs.extend(utc);
dayjs.extend(timezone);

interface EditorProps {
    content: string;
    saveContent: (content: string) => void; // Add the type for the saveContent prop
}

function fileListToImageFiles(fileList: FileList): File[] {
    return Array.from(fileList).filter((file) => {
        const mimeType = (file.type || "").toLowerCase();
        return mimeType.startsWith("image/");
    });
}

const EditEvent = () => {
    const rteRef = useRef<RichTextEditorRef>(null);
    const navigate = useNavigate();

    const { toggleAlert, changeAlertType } = useContext(appContext);

    const { jwtToken } = useOutletContext() as { jwtToken: string };
    const { setAlertTitle, setAlertMessage } = useOutletContext() as { setAlertTitle: (title: string) => void, setAlertMessage: (message: string) => void };
    const [errors, setErrors] = useState<string[]>([]);
    const [selectedOption, setSelectedOption] = useState<number | null>(null);

    let eventCategories = [
        {
            id: 1,
            value: "Bhagavad Gita"
        },
        {
            id: 2,
            value: "Srimad Bhagavatam"
        },
        {
            id: 3,
            value: "Kirtan večer"
        },
        {
            id: 4,
            value: "Caitanya Caritamrta"
        },
        {
            id: 5,
            value: "Posebna prigoda"
        },
        {
            id: 6,
            value: "Festival"
        },
        {
            id: 7,
            value: "Ostalo"
        },
    ];

    const hasError = (key: string) => {
        return errors.indexOf(key) !== -1;
    }

    const [eventPayload, setEvent] = useState({
        id: 0,
        title: "",
        author: 0,
        category: 0,
        description: "",
        start_datetime: "",
        end_datetime: "",
        created_at: "",
        updated_at: "",
    });

    let imageId = 0;
    const [imagesToDelete, setImagesToDelete] = useState<{ id: number; filename: string; }[]>([]);

    // get id from the URL
    let { id } = useParams();

    useEffect(() => {
        if (jwtToken === "") {
            navigate("/login");
            return;
        }
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${jwtToken}`);

        const requestOptions: RequestInit = {
            method: "GET",
            headers: headers,
            credentials: 'include',
        }

        fetch(`${process.env.REACT_APP_BACKEND}/admin/events/${id}`, requestOptions)
            .then((response) => {
                if (response.status === 401) {
                    console.log('Unauthorized');
                    navigate("/login");
                }

                return response.json();
            })
            .then((data) => {
                setEvent(data.event);
                setSelectedOption(data.event.category);
                rteRef.current?.editor?.commands.setContent(data.event.description);

            })
            .catch(err => {
                console.log(err);
            });
    }, [jwtToken, navigate, id])

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (jwtToken === "") {
            navigate("/login");
            return;
        }

        // all the images that were deleted in editor by user should now be deleted from upload folder and db
        // Promise.all(
        //     imagesToDelete.map((image) => deleteImage(image.filename)))
        //     .then(() => {
                const headers = new Headers();
                headers.append("Content-Type", "application/json");
                headers.append("Authorization", `Bearer ${jwtToken}`);
        
                // SAVE content from the description editor
                //let content:string = rteRef.current?.editor?.getHTML() || "";
                // eventPayload.description = content;
                //saveContent(JSON.stringify(content));
        
                const requestOptions = {
                    method: "Post",
                    headers: headers,
                    credentials: 'include' as RequestCredentials,
                    body: JSON.stringify(eventPayload, null, "\t"),
                }
        
                fetch(`${process.env.REACT_APP_BACKEND}/admin/update-event`, requestOptions)
                    .then((response) => {
                        if (response.status === 401) {
                            console.log('Unauthorized');
                            navigate("/login");
                        }
        
                        return response.json();
                    })
                    .then((data) => {
                        if (data.error) {
                            changeAlertType(alertTypes.Danger);
                            setAlertTitle("Warning");
                            setAlertMessage(data.message);
                        } else {
                            changeAlertType(alertTypes.Success);
                            setAlertTitle("Event was successfully updated!");
                            setAlertMessage(data.message);
                        }
        
                        toggleAlert(true);
                    })
                    .catch(err => {
                        changeAlertType(alertTypes.Danger);
                        setAlertTitle("Greška");
                        setAlertMessage(err);
                        toggleAlert(true);
                        console.log(err)
                    });
        //     })
        //     .catch((error) => {
        //     console.log(error);
        // });

       
    }

    const deleteImage = async (filename: string) => {
    
        if(jwtToken === "") {
            navigate("/login");
            return;
        }
        const headers = new Headers();
         //headers.append("Content-Type", "multipart/form-data; boundary=MultipartBoundry");
         headers.append("Authorization", `Bearer ${jwtToken}`);

        const requestOptions: RequestInit = {
          method: "GET",
          headers: headers,
          credentials: 'include' as RequestCredentials,
        };
    
        return fetch(`${process.env.REACT_APP_BACKEND}/admin/delete-image/${filename}/${"event"}/${id}`, requestOptions)
          .then((response) => {
              if(response.status === 401) {
                  console.log('Unauthorized');
                  navigate("/login");
              }
    
              return response.json();
          })
          .then((data) =>{
            if(data.error) {
                console.log(data.error)
                return data.error;
            } else {
                console.log('image deleted successfully')
                return data;
            }
    
          })
          .catch(err => {
            console.log(err)
            return err;
          });
      }

    const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;

        setEvent({
            ...eventPayload,
            [name]: value
        });
    }

    const handleCategoryChange = (name: string) => (event: React.ChangeEvent<HTMLSelectElement>) => {
        let value = parseInt(event.target.value);
        if (isNaN(value)) {
            value = 0;
        }

        setSelectedOption(value);

        setEvent({
            ...eventPayload,
            [name]: value
        });
    }

    const setEventCategory = (eventCategory: number) => {
        if (eventCategory > 0) {
            const result = eventCategories.find(({ id }) => id === eventCategory);
            return result;
        }
    }

    const getEventStartDateTime = (eventDateTime: string) => {
        if (eventDateTime !== "") {
            return dayjs(eventDateTime);
        }

        return dayjs(new Date());
    }

    const getEventEndDateTime = (eventDateTime: string) => {
        if (eventDateTime !== "") {
            return dayjs(eventDateTime);
        }
    }

    const setEventStartDateTime = (eventDateTime: string) => {
        if (eventDateTime !== "") {
            let date = dayjs(eventDateTime).locale('zh-cn');

            setEvent({
                ...eventPayload,
                ["start_datetime"]: date.format()
            });
        }
    }

    const setEventEndDateTime = (eventDateTime: string) => {
        if (eventDateTime !== "") {
            let date = dayjs(eventDateTime).locale('zh-cn');
    
            setEvent({
                ...eventPayload,
                ["end_datetime"]: date.format()
            });
        }
    }

    const handleDescriptionChange = () => {
        setEvent({
            ...eventPayload,
            ['description']: rteRef.current?.editor?.getHTML() || ""
        });
    }

    const handleImagesToDelete = (filename: string) => {
        setImagesToDelete(
            [
                ...imagesToDelete,
                { id: imageId++, filename: filename },
            ]
        );
    }

    const saveContent = (content: string) => {
        setEvent({
            ...eventPayload,
            ['description']: content
        });
    }

    // EDITOR
    const extensions = useExtensions({
        placeholder: "Add your own content here...",
    });

    const [isEditable, setIsEditable] = useState(true);
    const [showMenuBar, setShowMenuBar] = useState(true);

    const handleNewImageFiles = useCallback(
        (files: File[], insertPosition?: number): void => {
            if (!rteRef.current?.editor) {
                return;
            }

            files.map((file) => {
                console.log('Editor.tsx: ', file);
            });

            const attributesForImageFiles = files.map((file) => ({
                src: URL.createObjectURL(file),
                alt: file.name,
            }));

            insertImages({
                images: attributesForImageFiles,
                editor: rteRef.current.editor,
            });
        },
        [],
    );

    const handleDrop: NonNullable<EditorOptions["editorProps"]["handleDrop"]> =
        useCallback(
            (view, event, _slice, _moved) => {
                if (!(event instanceof DragEvent) || !event.dataTransfer) {
                    return false;
                }

                const imageFiles = fileListToImageFiles(event.dataTransfer.files);
                if (imageFiles.length > 0) {
                    const insertPosition = view.posAtCoords({
                        left: event.clientX,
                        top: event.clientY,
                    })?.pos;

                    handleNewImageFiles(imageFiles, insertPosition);

                    event.preventDefault();
                    return true;
                }

                return false;
            },
            [handleNewImageFiles],
        );

    const handlePaste: NonNullable<EditorOptions["editorProps"]["handlePaste"]> =
        useCallback(
            (_view, event, _slice) => {
                if (!event.clipboardData) {
                    return false;
                }

                const pastedImageFiles = fileListToImageFiles(
                    event.clipboardData.files,
                );
                if (pastedImageFiles.length > 0) {
                    handleNewImageFiles(pastedImageFiles);
                    return true;
                }

                return false;
            },
            [handleNewImageFiles],
        );

    const handleKeyPress: NonNullable<EditorOptions["editorProps"]["handleKeyPress"]> =
        useCallback(
            (_view:EditorView, event:Event) => {
                // if (!event.) {
                //     return false;
                // }
                // event.type == "keypress"
                // {

                //     console.log('---HANDLE_KEYPRESS---');
                //     console.log(event);
        
                // // }

                // return true;
            },
            [],
        );

    const handleKeyDown: NonNullable<EditorOptions["editorProps"]["handleKeyDown"]> =
        useCallback(
            (_view:EditorView, event:any) => {

                // if (event.type == "keydown") 
                // {
                    if (event.code == "Backspace" || event.code == "Delete")
                    {
                        let img:any = document.querySelector('img[class*="imageSelected"]');

                        if (img !== null)
                        {
                            if (img.src !== 'undefined')
                            {
                                let filename:string = img.src.split("filename=")[1];
                                if (filename !== "")
                                {
                                    deleteImage(filename);
                                    //handleImagesToDelete(filename);
                                }

                            }
                        }
                    }

                    
                // }
                // return; 
            },
            [handleImagesToDelete],
        );

    const handleTextInput: NonNullable<EditorOptions["editorProps"]["handleTextInput"]> =
        useCallback(
            (_view:EditorView, from:number, to:number, text:string ) => {

                // console.log('---HANDLETEXTINPUT---');
                // return true;
            },
            [],
        );

    // END EDITOR

    return (
        <>
                <br />
                <div className="col-md-10 offset-md-1">
                    <div><h1>Uredi događaj</h1></div>
                    <hr />
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" name="id" value={eventPayload.id} id="id"></input>
                        <Input
                            
                            title={"Title"}
                            className={"form-control"}
                            type={"text"}
                            name={"title"}
                            value={eventPayload.title}
                            onChange={handleChange("title")}
                            errorDiv={hasError("title") ? "text-danger" : "d-none"}
                            errorMsg={"Please enter a title"}
                        />

                        <div className="mb-3">
                            <label htmlFor="description" className="form-label">
                                Event Details
                            </label>

                            <Box
                                sx={{
                                    "& .ProseMirror": {
                                        "& h1, & h2, & h3, & h4, & h5, & h6": {
                                            scrollMarginTop: showMenuBar ? 50 : 0,
                                        },
                                    },
                                }}
                            >
                                <RichTextEditor
                                    ref={rteRef}
                                    extensions={extensions}
                                    content={eventPayload.description}
                                    editable={isEditable}
                                    editorProps={{
                                        handleKeyPress: handleKeyPress,
                                        handleKeyDown: handleKeyDown,
                                        handleTextInput: handleTextInput,
                                        handleDrop: handleDrop,
                                        handlePaste: handlePaste,
                                    }}
                                    renderControls={() => <EditorMenuControls />}
                                    // RichTextFieldProps={{
                                    //     variant: "outlined",
                                    //     MenuBarProps: {
                                    //         hide: !showMenuBar,
                                    //     },
                                    //     footer: (
                                    //         <Stack
                                    //             direction="row"
                                    //             spacing={2}
                                    //             sx={{
                                    //                 borderTopStyle: "solid",
                                    //                 borderTopWidth: 1,
                                    //                 borderTopColor: (theme) => theme.palette.divider,
                                    //                 py: 1,
                                    //                 px: 1.5,
                                    //             }}
                                    //         >
                                    //              <MenuButton
                                    //                 value="formatting"
                                    //                 tooltipLabel={
                                    //                     showMenuBar ? "Hide formatting" : "Show formatting"
                                    //                 }
                                    //                 size="small"
                                    //                 onClick={() =>
                                    //                     setShowMenuBar((currentState) => !currentState)
                                    //                 }
                                    //                 selected={showMenuBar}
                                    //                 IconComponent={TextFields}
                                    //             />

                                    //             <MenuButton
                                    //                 value="formatting"
                                    //                 tooltipLabel={
                                    //                     isEditable
                                    //                         ? "Prevent edits (use read-only mode)"
                                    //                         : "Allow edits"
                                    //                 }
                                    //                 size="small"
                                    //                 onClick={() => setIsEditable((currentState) => !currentState)}
                                    //                 selected={!isEditable}
                                    //                 IconComponent={isEditable ? Lock : LockOpen}
                                    //             />

                                    //             <Button
                                    //                 variant="contained"
                                    //                 size="small"
                                    //                 onClick={() => {
                                    //                     let content = rteRef.current?.editor?.getHTML() || "";

                                    //                     saveContent(content);
                                    //                 }}
                                    //             >
                                    //                 Save
                                    //             </Button>
                                    //         </Stack>
                                    //     ),
                                    // }}
                                   
                                >
                                    {() => (
                                        <>
                                            <LinkBubbleMenu />
                                            <TableBubbleMenu />
                                        </>
                                    )}
                                </RichTextEditor>
                            </Box>
                        </div>
                        <br />
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                            <DateTimePicker
                                name="startdate"
                                label="Event Start Date & Time"
                                className={"form-control"}
                                value={getEventStartDateTime(eventPayload.start_datetime)}
                                onChange={(newValue:any) => setEventStartDateTime(newValue?.toString())}
                            />
                        </LocalizationProvider>
                        <br /><br />
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                            <DateTimePicker
                                name="enddate"
                                label="Event End Date & TIme"
                                className={"form-control"}
                                value={getEventEndDateTime(eventPayload.end_datetime)}
                                onChange={(newValue:any) => setEventEndDateTime(newValue)}
                            />
                        </LocalizationProvider>

                        <br /><br />

                        <Select
                            title={"Event type"}
                            name={"category"}
                            options={eventCategories}
                            value={selectedOption}
                            onChange={handleCategoryChange("category")}
                            placeHolder={"Please choose event category..."}
                            errorDiv={hasError("category") ? "text-danger" : "d-none"}
                            errorMsg={"Please choose"}
                        />

                        <Button type="submit" variant="outlined" onClick={() => {
                            let content = rteRef.current?.editor?.getHTML() || "";
                            saveContent(content);
                        }}
                        >Save</Button>
                    </form>
                </div>
                <br />
        </>
    )
}

export default EditEvent;
